import React, { useEffect, useRef } from "react";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const Toaster = ({ message, setMessage }) => {
  const timerRef = useRef(null);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    } else {
      timerRef.current = setTimeout(() => {
        setMessage({ type: "", text: "" });
      }, 2000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div className={joinClass(styles["toaster"], styles[message.type])}>
      {message.text}
    </div>
  );
};

export default Toaster;
