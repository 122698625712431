import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const Button = ({
  children,
  className,
  gradientButton,
  transparent,
  shadow,
  alter,
  onClick,
  disabled,
  styleReverse,
  cancelButton,
  type,
}) => {
  return (
    <button
      className={joinClass(
        styles.button,
        gradientButton && styles["button--gradient"],
        transparent && styles["button--transparent"],
        shadow && styles["button--shadow"],
        alter && styles["button--alter"],
        styleReverse && styles["button--reverse"],
        cancelButton && styles["button--cancel"],
        className
      )}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
