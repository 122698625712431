import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import PageHeader from "../../../../components/PageHeader";
import TextInput from "../../../../components/TextInput";
import Toaster from "../../../../components/Toaster";
import requestMethods from "../../../../helpers/request";
import {
  getItemFromLocalStorage,
  handleSpeak,
  history,
} from "../../../../helpers/utils";
import styles from "./index.module.css";

const SurgicalSpongePackingCreate = () => {
  const { units = [], company: { id: companyId } = {} } =
    getItemFromLocalStorage("user") || {};
  const [packetId, setPacketId] = useState("");
  const [spongeId, setSpongeId] = useState("");
  const [spongeList, setSpongeList] = useState([]);
  const [message, setMessage] = useState({ text: "", type: "" });
  const [isDisabledSpongeId, toggleDisabledSpongeId] = useState(false);
  const [isDisabledPackId, toggleDisabledPackId] = useState(false);
  const [isCreate, toggleCreate] = useState(false);

  const packetRef = useRef(null);
  const spongeRef = useRef(null);
  const timerRef = useRef(null);
  const synthRef = useRef(null);

  useEffect(() => {
    return () => {
      if (synthRef.current) synthRef.current.cancel();
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (packetId) {
      toggleDisabledSpongeId(true);

      timerRef.current = setTimeout(() => {
        toggleDisabledPackId(true);
        if (spongeList.find((sponge) => sponge.id === packetId)) {
          setMessage({ text: "Cannot be same", type: "error" });
          handleSpeak("Cannot be same", synthRef, () => {
            if (packetRef.current) {
              toggleDisabledPackId(false);
              toggleDisabledSpongeId(false);
              setPacketId("");
              packetRef.current.focus();
            }
          });
        } else {
          setMessage({ type: "success", text: "Pack ID added" });
          handleSpeak("Pack ID added", synthRef, () => {
            if (spongeRef.current) {
              toggleDisabledPackId(false);
              toggleDisabledSpongeId(false);
              spongeRef.current.focus();
            }
          });
        }
      }, 500);
    }
  }, [packetId]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (spongeId) {
      toggleDisabledPackId(true);
      timerRef.current = setTimeout(() => {
        const message = { text: "", type: "error" };
        toggleDisabledSpongeId(true);
        if (spongeList.find((sponge) => sponge.id === spongeId)) {
          message.text = "Sponge already exist";
        } else if (spongeId === packetId) {
          message.text = "Cannot be same";
        } else {
          message.text = (spongeList.length + 1).toString();
          message.type = "success";
          setSpongeList([...spongeList, { id: spongeId }]);
        }
        setMessage(message);
        handleSpeak(message.text, synthRef, () => {
          if (spongeRef.current) {
            setSpongeId("");
            toggleDisabledSpongeId(false);
            toggleDisabledPackId(false);
            spongeRef.current.focus();
          }
        });
      }, 500);
    }
  }, [spongeId]);

  const textBoxList = [
    {
      key: 1,
      label: "Pack ID",
      value: packetId,
      onChange: (e) => setPacketId(e),
      required: true,
      autoFocus: true,
      inputRef: packetRef,
      disabled: isDisabledPackId,
    },
    {
      key: 2,
      label: "Add Sponge",
      value: spongeId,
      onChange: (e) => setSpongeId(e),
      inputRef: spongeRef,
      disabled: isDisabledSpongeId,
    },
  ];

  const handlePack = () => {
    toggleCreate(true);

    if (spongeList.length > 0 && packetId) {
      requestMethods
        .POST({
          endpoint: `v4/surgery/sponge-packet/${companyId}/${units[0].id}/create`,
          body: {
            code: packetId,
            sponge_codes: spongeList.map((sponge) => sponge.id),
          },
        })
        .then(() => {
          setMessage({
            type: "success",
            text: "Pack created successfully",
          });

          setTimeout(
            () => history.push("/surgical_sponge_track_home/packing"),
            1000
          );
        })
        .catch((e) => setMessage({ type: "error", text: e.message }));
    } else {
      let message = "";
      if (!packetId) {
        message = "Enter pack ID";
        if (packetRef.current) packetRef.current.focus();
      } else {
        message = "Add atleast one sponge to pack";
        if (spongeRef.current) spongeRef.current.focus();
      }
      handleSpeak(message, synthRef, () => toggleCreate(false));
      setMessage({ text: message, type: "error" });
    }
  };

  return (
    <div className={styles["surgical-sponge-packing-create"]}>
      <PageHeader
        themeHeader
        title="Create Pack"
        returnHome="/surgical_sponge_track_home/packing"
      />
      {message.text && <Toaster message={message} setMessage={setMessage} />}
      <div className={styles["surgical-sponge-packing-create__main-section"]}>
        <div
          className={styles["surgical-sponge-packing-create__input-container"]}
        >
          {textBoxList.map((textBox) => (
            <TextInput
              type="text"
              {...textBox}
              className={styles["surgical-sponge-packing-create__text"]}
            />
          ))}
        </div>
        <div className={styles["surgical-sponge-packing-create__title"]}>
          <div>{"Sponges"}</div>
        </div>
        <div className={styles["surgical-sponge-packing-create__table"]}>
          <div
            className={
              styles["surgical-sponge-packing-create__columns-container"]
            }
          >
            <div>{"Sl No"}</div>
            <div>{"Sponge ID"}</div>
          </div>
          {(spongeList.length > 0 && (
            <div
              className={
                styles["surgical-sponge-packing-create__row-container"]
              }
            >
              {spongeList.map((sponge, index) => (
                <div key={sponge.id}>
                  <div>{index + 1}</div>
                  <div>{sponge.id}</div>
                </div>
              ))}
            </div>
          )) || (
            <div
              className={styles["surgical-sponge-packing-create__no-record"]}
            >
              {"No records"}
            </div>
          )}
        </div>
        <div
          className={styles["surgical-sponge-packing-create__button-container"]}
        >
          <Button
            type="button"
            cancelButton
            onClick={() => history.push("/surgical_sponge_track_home/packing")}
            className={styles["surgical-sponge-packing-create__button"]}
          >
            {"Back"}
          </Button>
          <Button
            type="button"
            onClick={handlePack}
            className={styles["surgical-sponge-packing-create__button"]}
            disabled={isCreate}
          >
            {"Pack"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SurgicalSpongePackingCreate;
