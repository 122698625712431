import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeader";

import { getItemFromLocalStorage, timeDifference } from "../../helpers/utils";
import styles from "./index.module.css";

const History = () => {
  const history = getItemFromLocalStorage("history") || [];

  return (
    <div>
      <PageHeader themeHeader title="History" />
      <div className={styles.history}>
        {history.length === 0 ? (
          <p>No Items</p>
        ) : (
          <ul className={styles.history__list}>
            {history.map(({ name, id, time }) => (
              <li key={id} className={styles.history__item}>
                <div className={styles["history__label-container"]}>
                  <label>{name}</label>
                  <span>{timeDifference(+new Date().getTime(), +time)}</span>
                </div>
                <Link to={`/asset/${id}`} className={styles.history__link}>
                  <AiOutlineRight />
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default History;
