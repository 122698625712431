import { createBrowserHistory } from "history";

export const joinClass = (...classNames) => classNames.join(" ");

export const history = createBrowserHistory();

export const redirect = (path) => history.push(path);

export const getItemFromLocalStorage = (id) =>
  JSON.parse(localStorage.getItem(id));

export const getFormatedDate = (timestamp) =>
  new Date(+timestamp).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

export const moveInArray = (arr, from, to) => {
  const newArr = arr.slice();
  const item = newArr.splice(from, 1);
  newArr.splice(to, 0, item[0]);

  return newArr;
};

export const timeDifference = (current, previous) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return "approximately " + Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return "approximately " + Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return "approximately " + Math.round(elapsed / msPerYear) + " years ago";
  }
};

export const removeUnderScore = (word) => {
  const arrayString = word.split("_");
  return arrayString
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");
};

export const sort = (element1, element2) => {
  const nameA = element1.name?.toLowerCase();
  const nameB = element2.name?.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export const filteredDepartmentList = (departmentList, type, cssdEnabled) => {
  let newList = departmentList;
  if (cssdEnabled) {
    newList = departmentList.filter((department) => department.cssd_enabled);
  }

  if (type === "owner") {
    newList = newList.filter((el) => el.asset_owner);
  } else if (type === "custodian") {
    newList = newList.filter((el) => el.asset_custodian);
  } else if (type === "all") {
    newList = (cssdEnabled && newList) || departmentList;
  }
  const sorted = newList.sort((element1, element2) => sort(element1, element2));
  return sorted;
};

export const handleSpeak = (value, synthRef, endFn) => {
  if (synthRef.current) {
    synthRef.current.cancel();
  }
  synthRef.current = window.speechSynthesis;
  var voices = synthRef.current.getVoices();
  const utter = new SpeechSynthesisUtterance();
  utter.voice = voices[2];
  utter.volume = 1;

  if (endFn) {
    utter.onend = (event) => {
      endFn();
    };
  }
  utter.text = value;
  synthRef.current.speak(utter);
};
