import React, { useEffect, useState } from "react";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import Button from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import PageHeader from "../../../components/PageHeader";
import Pagination from "../../../components/Pagination";
import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  history,
  joinClass,
  removeUnderScore,
} from "../../../helpers/utils";
import styles from "./index.module.css";

const Inspect = () => {
  const {
    company: { id: companyId } = {},
    units = [],
    departments = [],
  } = getItemFromLocalStorage("user") || {};

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [errorMessage, setErrorMessage] = useState("");
  const [cssdKits, setCssdKits] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tab, setTab] = useState("kits");
  const [originArea, setOriginArea] = useState(null);
  const [areas, setAreas] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [isInspectAsset, toggleInspectAsset] = useState(false);
  const [row, setRow] = useState(null);
  const [condition, setCondition] = useState("");
  const [availability, setAvailability] = useState(false);
  const [isNextStep, toggleNextStep] = useState(false);
  const [complete, toggleComplete] = useState(false);

  useEffect(() => {
    if (originArea?.id && tab === "kits") {
      requestMethods
        .GET({
          endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
          params: {
            custodianDepartmentId: departments[0].id,
            page,
            size: rowsPerPage,
            status: "awaiting_inspect",
            location: originArea?.id,
          },
        })
        .then((res) => {
          if (tab === "kits") setTotalCount(res.total_count);
          setCssdKits(res.cssd_kits);
        })
        .catch((err) => setErrorMessage(err.message));
    }
  }, [page, rowsPerPage, originArea, tab]);

  useEffect(() => {
    if (originArea?.id && tab === "instruments") {
      requestMethods
        .GET({
          endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
          params: {
            custodian_department_id: departments[0].id,
            ignore_paging: false,
            cssdStatus: "awaiting_inspect",
            is_kit_assigned: "unassigned",
            home_location_id: originArea?.id,
            page,
            size: rowsPerPage,
          },
        })
        .then((res) => {
          setInstruments(res.assets);
          if (tab === "instruments") setTotalCount(res.total_count);
        })
        .catch((err) => setErrorMessage(err.message));
    }
  }, [tab, page, rowsPerPage, originArea]);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/area/${companyId}/${units[0].id}/get-areas`,
        params: { ignore_paging: true },
      })
      .then((res) => {
        setAreas(res.areas);
      })
      .catch((err) => setErrorMessage(err.message));
  }, []);

  const kitColumns = ["code", "name"];

  const tabList = [
    {
      key: "kits",
      label: "KITs",
      disabled: false,
    },
    {
      key: "instruments",
      label: "Instruments",
      disabled: false,
    },
  ];

  useEffect(() => {
    setOriginArea(handleOriginAreas()[0]);
  }, [areas]);

  const handleOriginAreas = () => {
    const newAreaList = [];

    departments[0].allowed_areas?.forEach((areaId) => {
      if (areas.find((area) => area.id === areaId)) {
        newAreaList.push(areas.find((area) => area.id === areaId));
      }
    });

    return newAreaList;
  };

  const data = (tab === "kits" && cssdKits) || instruments || [];

  const radioButtons = [
    { id: "good", label: "Good" },
    { id: "damaged", label: "Damaged" },
    { id: "non_usable", label: "Non Usable" },
  ];

  const [nextProcess, setNextProcess] = useState({
    process: "",
    status: "",
    label: "",
  });
  const [isUpdateAssetCondition, toggleUpdateAssetCondition] = useState(false);

  useEffect(() => {
    if (row?.id) {
      if (
        row.updateCondition !== condition ||
        row.updateAvailability !== availability
      ) {
        toggleUpdateAssetCondition(true);
      } else {
        toggleUpdateAssetCondition(false);
      }
    }
  }, [row, availability, condition]);

  const checkBoxList = [
    {
      key: 1,
      process: "wash",
      name: "Wash",
      status: "awaiting_wash",
      id: "wash",
    },
    {
      key: 2,
      process: "pack",
      name: "Pack",
      status: "awaiting_pack",
      id: "pack",
    },
    {
      key: 3,
      process: "sterilize",
      name: "Sterilize",
      status: "awaiting_sterilize",
      id: "sterilize",
    },
    {
      key: 4,
      process: "dispatch",
      name: "Dispatch",
      status: "awaiting_dispatch",
      id: "dispatch",
    },
  ];

  useEffect(() => {
    let nextProcess = null;
    for (let i = 0; i < checkBoxList.length; i++) {
      if (departments[0]?.allowed_process?.includes(checkBoxList[i].process)) {
        nextProcess = checkBoxList[i];
        break;
      }
    }

    setNextProcess(nextProcess);
  }, []);

  const handleInspect = () => {
    toggleComplete(true);
    const availableDevices = {};
    const devicesCondition = {};

    availableDevices[row.id] = availability;
    devicesCondition[row.id] = condition;

    const body = {
      entity_id: row.id,
      device_occupancy_status: availableDevices,
      device_condition: devicesCondition,
      next_phase: nextProcess.process,
      kit_status: nextProcess.status,
      update_asset_condition: isUpdateAssetCondition,
      entity_type: "asset",
    };

    requestMethods
      .POST({
        endpoint: `v4/cssd/kit/inspect/${companyId}/${units[0].id}/create`,
        body,
      })
      .then((res) => {
        setTimeout(() => {
          toggleInspectAsset(false);
          toggleComplete(false);

          if (tab === "kits") {
            requestMethods
              .GET({
                endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
                params: {
                  custodianDepartmentId: departments[0].id,
                  page,
                  size: rowsPerPage,
                  status: "awaiting_inspect",
                  location: originArea?.id,
                },
              })
              .then((res) => {
                setCssdKits(res.cssd_kits);
                setTotalCount(res.total_count);
              })
              .catch((err) => setErrorMessage(err.message));
          } else {
            requestMethods
              .GET({
                endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
                params: {
                  custodian_department_id: departments[0].id,
                  ignore_paging: false,
                  cssdStatus: "awaiting_inspect",
                  is_kit_assigned: "unassigned",
                  home_location_id: originArea?.id,
                  page,
                  size: rowsPerPage,
                },
              })
              .then((res) => {
                setInstruments(res.assets);
                setTotalCount(res.total_count);
              })
              .catch((err) => setErrorMessage(err.message));
          }
        }, 1000);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        toggleComplete(false);
      });
  };

  useEffect(() => {
    if (cssdKits.length < 0 && instruments.length < 0) {
      setErrorMessage("No data available");
    } else {
      setErrorMessage("");
    }
  }, [cssdKits, instruments]);

  const handleEmpty = () => {
    return (
      <div className={styles["inspect__empty"]}>
        {"There are no records to display"}
      </div>
    );
  };

  return (
    <div className={styles["inspect"]}>
      <PageHeader themeHeader title={"Inspect"} returnHome={"/cssd_home"} />
      <NewModal
        isOpen={isInspectAsset}
        onCancel={() => toggleInspectAsset(false)}
      >
        {(!complete && (
          <div>
            <div className={styles["inspect__preview"]}>
              <div>
                <div>{"Code"}</div>
                <div>{row?.code}</div>
              </div>
              <div>
                <div>{"Name"}</div>
                <div>{row?.name}</div>
              </div>
            </div>
            <div className={styles["inspect__input-container"]}>
              <div className={styles["inspect__checkbox"]}>
                <div>{"Availability:"}</div>
                {(row?.updateAvailability && (
                  <input
                    type={"checkbox"}
                    checked={availability}
                    onChange={() => {
                      setAvailability(!availability);
                    }}
                  />
                )) || <div>{"Not Available"}</div>}
              </div>
              {(row?.updateCondition === "good" && (
                <AdvancedDropdown
                  options={radioButtons}
                  value={{ id: condition, name: removeUnderScore(condition) }}
                  onChange={(value) => setCondition(value.id)}
                  label={"Condition"}
                  labelClass={styles["inspect__dropdown"]}
                />
              )) || (
                <div className={styles["inspect__condition"]}>
                  <div>{"Condition:"}</div>
                  <div>{removeUnderScore(row?.updateCondition || "")}</div>
                </div>
              )}
            </div>
            <div className={styles["radio-container"]}>
              <div className={styles["radio-container__process"]}>
                <label>{"Next Process :"}</label>
                <label>{nextProcess.name}</label>
              </div>
              <div className={styles["radio-container__check"]}>
                <u onClick={() => toggleNextStep(!isNextStep)}>{"Change ?"}</u>
              </div>
              {isNextStep && (
                <AdvancedDropdown
                  options={checkBoxList.filter((item) =>
                    departments[0]?.allowed_process?.includes(item.process)
                  )}
                  value={nextProcess}
                  onChange={(value) => setNextProcess(value)}
                />
              )}
            </div>
            <div className={styles["inspect__button-container"]}>
              <Button
                onClick={() => toggleInspectAsset(false)}
                cancelButton
                className={styles["inspect__button"]}
              >
                {"Cancel"}
              </Button>
              <Button
                onClick={() => handleInspect()}
                className={styles["inspect__button"]}
                disabled={complete}
              >
                {"Complete"}
              </Button>
            </div>
          </div>
        )) || (
          <div className={styles["inspect__success-message"]}>
            {"Inspected Successfully"}
          </div>
        )}
      </NewModal>
      <div className={styles["inspect__main-section"]}>
        <div className={styles["inspect__filter"]}>
          <AdvancedDropdown
            label={"Department"}
            options={departments}
            value={departments[0]}
            disabled={true}
          />
          <AdvancedDropdown
            label={"Area"}
            value={originArea}
            options={handleOriginAreas()}
            onChange={(value) => setOriginArea(value)}
            disabled={handleOriginAreas().length === 1}
          />
        </div>
        <div className={styles["inspect__tab-container"]}>
          {tabList.map((item) => (
            <div
              id={item.key}
              key={item.key}
              className={joinClass(
                styles["inspect__tab-container__tab"],
                item.disabled && styles["inspect__tab-container__disabled"],
                tab === item.key &&
                  !item.disabled &&
                  styles["inspect__tab-container__active"]
              )}
              onClick={() => {
                if (!item.disabled) {
                  setTab(item.key);
                  setPage(1);
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        {(cssdKits.length > 0 && tab === "kits" && (
          <div className={styles["inspect__table"]}>
            <div className={styles["inspect__column-container"]}>
              {kitColumns.map((item) => (
                <div key={item}>{removeUnderScore(item)}</div>
              ))}
            </div>
            <div className={styles["inspect__row-container"]}>
              {cssdKits.map((kit) => (
                <div
                  onClick={() =>
                    history.push(
                      `/cssd_home/inspect/inspect_kit/${units[0].id}/${kit.id}`
                    )
                  }
                  className={styles["inspect__row"]}
                  key={kit.id}
                >
                  <div>{kit.code}</div>
                  <div>{kit.name}</div>
                </div>
              ))}
            </div>
          </div>
        )) ||
          (tab === "kits" && handleEmpty())}
        {(instruments.length > 0 && tab === "instruments" && (
          <div className={styles["inspect__table"]}>
            <div className={styles["inspect__column-container"]}>
              {kitColumns.map((item) => (
                <div key={item}>{removeUnderScore(item)}</div>
              ))}
            </div>
            <div className={styles["inspect__row-container"]}>
              {instruments.map((instrument) => (
                <div
                  onClick={() => {
                    const availability =
                      (instrument.availability === "available" && true) ||
                      false;
                    const condition =
                      (instrument.condition === "active" && "good") ||
                      instrument.condition;
                    toggleInspectAsset(true);
                    setRow({
                      ...instrument,
                      updateAvailability: availability,
                      updateCondition: condition,
                    });
                    setAvailability(availability);
                    setCondition(condition);
                  }}
                  className={styles["inspect__row"]}
                  key={instrument.id}
                >
                  <div>{instrument.code}</div>
                  <div>{instrument.name}</div>
                </div>
              ))}
            </div>
          </div>
        )) ||
          (tab === "instruments" && handleEmpty())}
        {data.length > 0 && (
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            setPage={setPage}
            listCount={
              (tab === "kits" && cssdKits.length) || instruments.length
            }
          />
        )}
      </div>
      <div className={styles["inspect__message"]}>
        {errorMessage && removeUnderScore(errorMessage)}
      </div>
    </div>
  );
};

export default Inspect;
