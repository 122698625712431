import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../../../components/PageHeader";
import TextInput from "../../../../components/TextInput";
import requestMethods from "../../../../helpers/request";
import {
  getItemFromLocalStorage,
  handleSpeak,
} from "../../../../helpers/utils";
import Button from "../../../../components/Button";
import Toaster from "../../../../components/Toaster";
import { history as redirect } from "../../../../helpers/utils";
import SpongeValidation from "./SpongeValidation";
import styles from "./index.module.css";

const SurgeryCreate = (history) => {
  const { unitId, surgeryId } = history.match.params;
  const { units = [], company: { id: companyId } = {} } =
    getItemFromLocalStorage("user") || {};

  const [packetList, setPacketList] = useState([]);
  const [currentPackList, setCurrentPackList] = useState([]);
  const [surgeryCode, setSurgeryCode] = useState("");
  const [packetId, setPacketId] = useState("");
  const [message, setMessage] = useState({ type: "", text: "" });
  const [isDisabledSurgeryId, toggleDisabledSurgeryId] = useState(false);
  const [isDisabledPackId, toggleDisabledPackId] = useState(false);
  const [isCreate, toggleCreate] = useState(false);
  const [isOpen, toggleOpen] = useState(false);
  const [packet, setPacket] = useState(null);
  const [validateSponge, toggleValidateSponge] = useState(false);
  const [choice, setChoice] = useState(false);
  const [spongeId, setSpongeId] = useState("");

  const surgeryRef = useRef(null);
  const packetRef = useRef(null);
  const synthRef = useRef(null);
  const timerRef = useRef(null);
  const spongeRef = useRef(null);
  const spongeTimerRef = useRef(null);

  useEffect(() => {
    return () => {
      synthRef.current && synthRef.current.cancel();
    };
  }, []);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/surgery/sponge-packet/${companyId}/${units[0].id}/get-all`,
        params: { status: "packed", ignore_paging: true },
      })
      .then((res) => {
        if (res.status) {
          setCurrentPackList(res.surgical_sponge_packet_responses);
        }
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.message });
      });
  }, []);

  const handleAvailability = (packet, item) => {
    if (packet?.missed_sponges?.length > 0) {
      if (
        packet?.missed_sponges?.includes(item) ||
        packet?.newly_received_sponges?.includes(item)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (packet?.newly_received_sponges?.includes(item)) {
        return false;
      } else {
        return true;
      }
    }
  };

  useEffect(() => {
    if (surgeryId) {
      requestMethods
        .GET({
          endpoint: `v4/surgery/${companyId}/${unitId}/details/${surgeryId}`,
        })
        .then((res) => {
          if (res.status) {
            setPacketList([
              ...packetList,
              ...res?.associated_sponge_packets?.map((packet) => ({
                ...packet,
                needs_to_validate: false,
                newPacket: false,
                sponge_codes: packet?.sponge_codes?.map((item) => ({
                  id: item,
                  available: handleAvailability(packet, item),
                  type: packet?.newly_received_sponges?.includes(item)
                    ? "unknown"
                    : "current",
                })),
              })),
            ]);
            setSurgeryCode(res.code);
          }
        })
        .catch((error) => setMessage({ type: "error", text: error.message }));
    }
  }, [surgeryId]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (surgeryCode) {
      timerRef.current = setTimeout(() => {
        if (packetList.find((pack) => pack.code === surgeryCode)) {
          toggleDisabledSurgeryId(true);
          toggleDisabledPackId(true);
          setMessage({ type: "error", text: "Cannot be same" });
          handleSpeak("Cannot be same", synthRef, () => {
            if (surgeryRef.current) {
              setSurgeryCode("");
              toggleDisabledSurgeryId(false);
              toggleDisabledPackId(false);
              surgeryRef.current.focus();
            }
          });
        }
      }, 500);
    }
  }, [surgeryCode]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (packetId) {
      toggleDisabledSurgeryId(true);
      timerRef.current = setTimeout(() => {
        toggleDisabledPackId(true);
        const message = { type: "error", text: "" };
        if (packetList?.find((packet) => packet?.code === packetId)) {
          message.text = "Pack already exist";
        } else if (packetId === surgeryCode) {
          message.text = "Cannot be same";
        } else {
          const packet = currentPackList?.find(
            (item) => item?.code === packetId
          );
          if (packet) {
            message.text = `Pack of ${packet?.sponge_codes?.length} sponges added`;
            message.type = "success";
            setPacket({
              ...packet,
              unknown_sponges: [],
              needs_to_validate: false,
              sponge_codes: packet?.sponge_codes?.map((item) => ({
                id: item,
                available: true,
                type: "current",
              })),
            });
            toggleOpen(true);
          } else {
            message.text = "Not available";
          }
        }
        setMessage(message);
        handleSpeak(message.text, synthRef, () => {
          if (packetRef.current) {
            toggleDisabledSurgeryId(false);
            toggleDisabledPackId(false);
            setPacketId("");
            message.type === "error" && packetRef.current.focus();
          }
        });
      }, 500);
    }
  }, [packetId, currentPackList]);

  useEffect(() => {
    if (spongeTimerRef.current) {
      clearTimeout(spongeTimerRef.current);
    }

    if (spongeId) {
      spongeTimerRef.current = setTimeout(() => {
        let message = {
          type: "error",
          text: "Something went wrong",
        };

        const sponge = packet?.sponge_codes?.find(
          (sponge) => sponge.id === spongeId
        );

        if (sponge) {
          setChoice(false);
          if (sponge.type === "current") {
            message.type = "success";
            setPacket({
              ...packet,
              sponge_codes: packet.sponge_codes?.map((sponge) => {
                if (sponge?.id === spongeId) {
                  return { ...sponge, available: true };
                } else return sponge;
              }),
            });
            message.text = "Sponge added";
          } else {
            message.text = "Unknown Sponge - Already added";
          }
          setSpongeId("");
        } else {
          if (packet.unknown_sponges.includes(spongeId)) {
            message.text = "Unknown Sponge - Already added";
            setSpongeId("");
          } else {
            setChoice(true);
            message.text = "Unknown Sponge";
          }
        }
        setMessage(message);
      }, 500);
    }
  }, [spongeId]);

  const handleCreate = () => {
    toggleCreate(true);
    if (packetList.length > 0 && surgeryCode) {
      if (synthRef.current) {
        synthRef.current.cancel();
      }

      if (packetList.find((packet) => packet.newPacket)) {
        const packIds = [];
        packetList?.forEach((pack) => {
          if (pack?.newPacket) {
            packIds?.push(pack?.id);
          }
        });

        const spongeValidation = {};

        packetList.forEach((packet) => {
          if (packet.newPacket) {
            const sponges = [];

            packet?.sponge_codes?.forEach((sponge) => {
              if (sponge?.available || sponge?.type === "unknown") {
                sponges.push(sponge?.id);
              }
            });

            if (packet?.validate) {
              spongeValidation[packet?.id] = {
                needs_to_validate:
                  packet?.unknown_sponges?.length > 0 ? false : true,
                sponges,
                ...(packet?.unknown_sponges?.length > 0 && {
                  unknown_sponges: packet?.unknown_sponges,
                }),
              };
            }
          }
        });

        const body = {
          code: surgeryCode,
          associated_sponge_packets: packIds,
          sponge_validation: spongeValidation,
        };

        const dispatch =
          (surgeryId && requestMethods.PUT) || requestMethods.POST;

        dispatch({
          endpoint: `v4/surgery/${companyId}/${units[0].id}/${
            (surgeryId && `update-packet/${surgeryId}`) || "create"
          }`,
          body,
        })
          .then(() => {
            setMessage({
              type: "success",
              text: `Surgery ${
                (surgeryId && "updated") || "created"
              } successfully`,
            });

            setTimeout(
              () => redirect.push("/surgical_sponge_track_home/ot"),
              1000
            );
          })
          .catch((error) => {
            setMessage({ type: "error", text: error.message });
            toggleCreate(false);
          });
      } else {
        setMessage({ type: "error", text: "No new packets added" });
        toggleCreate(false);
      }
    } else {
      let message = "";
      if (!surgeryCode) {
        message = "Enter surgery id";
        if (surgeryRef.current) surgeryRef.current.focus();
      } else {
        message = "Add atleast one pack to entry";
        if (packetRef.current) packetRef.current.focus();
      }
      setMessage({ type: "error", text: message });
      toggleCreate(false);
    }
  };

  const handleClearModal = () => {
    toggleOpen(false);
    setChoice(false);
    setSpongeId("");
    toggleValidateSponge(false);
    packetRef.current.focus();
  };

  return (
    <div className={styles["surgery-create"]}>
      <PageHeader
        themeHeader
        title="OT Entry"
        returnHome="/surgical_sponge_track_home/ot"
      />
      <SpongeValidation
        isOpen={isOpen}
        handleClearModal={handleClearModal}
        packet={packet}
        validateSponge={validateSponge}
        toggleValidateSponge={toggleValidateSponge}
        spongeId={spongeId}
        setSpongeId={setSpongeId}
        setMessage={setMessage}
        setPacketList={setPacketList}
        spongeRef={spongeRef}
        choice={choice}
        setChoice={setChoice}
        setPacket={setPacket}
        packetList={packetList}
      />

      {message.text && <Toaster message={message} setMessage={setMessage} />}
      <div className={styles["surgery-create__main-section"]}>
        <div className={styles["surgery-create__tooltip"]}>
          <div>{"Sponge Status :"}</div>
          <div>
            <div>
              <div></div>
              <div>{"Available"}</div>
            </div>
            <div>
              <div></div>
              <div>{"Missing"}</div>
            </div>
            <div>
              <div></div>
              <div>{"Unknown"}</div>
            </div>
          </div>
        </div>
        <div className={styles["surgery-create__input-container"]}>
          <TextInput
            type="text"
            value={surgeryCode}
            onChange={(e) => setSurgeryCode(e)}
            className={styles["surgery-create__text"]}
            label={"Surgery ID"}
            required
            autoFocus
            inputRef={surgeryRef}
            disabled={surgeryId || isDisabledSurgeryId}
          />
          <TextInput
            type="text"
            className={styles["surgery-create__text"]}
            value={packetId}
            onChange={(value) => setPacketId(value)}
            label="Pack ID"
            inputRef={packetRef}
            {...(surgeryId && { autoFocus: true })}
            disabled={isDisabledPackId}
          />
        </div>
        <div className={styles["surgery-create__title"]}>
          <div>{"Packs"}</div>
        </div>
        <div className={styles["surgery-create__table"]}>
          <div className={styles["surgery-create__columns-container"]}>
            <div>{"Sl No"}</div>
            <div>{"Pack ID"}</div>
            <div>{"# of sponges"}</div>
            <div>{"Sponge Validate"}</div>
            <div>{"Sponges"}</div>
            <div>{"Missed Sponges"}</div>
            <div>{"Unknown Sponges"}</div>
          </div>
          {(packetList?.length > 0 && (
            <div className={styles["surgery-create__row-container"]}>
              {packetList?.map((packet, index) => (
                <div key={packet?.id}>
                  <div>{index + 1}</div>
                  <div>{packet?.code}</div>
                  <div>
                    {
                      packet?.sponge_codes?.filter(
                        (sponge) => sponge?.type === "current"
                      )?.length
                    }
                  </div>
                  <div>{(packet.validate && "Yes") || "No"}</div>
                  <div className={styles["surgery-create__box"]}>
                    {packet?.sponge_codes?.map((sponge) => (
                      <div
                        key={sponge.id}
                        className={
                          styles[
                            `surgery-create__${
                              (sponge.available && "available") ||
                              (sponge.type === "unknown" && "new") ||
                              "missing"
                            }`
                          ]
                        }
                      >
                        <div>{sponge.id}</div>
                        {sponge.type === "unknown" && (
                          <div>{"(Newly added)"}</div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className={styles["surgery-create__box"]}>
                    {packet?.missed_sponges?.map((sponge) => (
                      <div
                        key={(sponge?.id && sponge?.id) || sponge}
                        className={styles["surgery-create__missing"]}
                      >
                        {(sponge?.id && sponge?.id) || sponge}
                      </div>
                    ))}
                  </div>
                  <div className={styles["surgery-create__box"]}>
                    {packet?.unknown_sponges?.map((sponge) => (
                      <div
                        key={sponge}
                        className={styles["surgery-create__not-available"]}
                      >
                        {sponge}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )) || (
            <div className={styles["surgery-create__no-record"]}>
              {"No records"}
            </div>
          )}
        </div>
        <div className={styles["surgery-create__button-container"]}>
          <Button
            type="button"
            cancelButton
            onClick={() => redirect.push("/surgical_sponge_track_home/ot")}
            className={styles["surgery-create__button"]}
          >
            {"Back"}
          </Button>
          <Button
            type="button"
            onClick={handleCreate}
            className={styles["surgery-create__button"]}
            disabled={isCreate}
          >
            {"Mark Entry"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SurgeryCreate;
