import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AdvancedDropdown from "../../components/AdvancedDropdown";
import Button from "../../components/Button";
import { DatePicker } from "../../components/DatePicker";
import RadioButton from "../../components/RadioButton";
import TextInput from "../../components/TextInput";
import requestMethods from "../../helpers/request";
import moment from "moment";
import { getItemFromLocalStorage, removeUnderScore } from "../../helpers/utils";
import PageLayout from "../../components/PageLayout";
import PageHeader from "../../components/PageHeader";
import styles from "./index.module.css";

const GatePass = () => {
  const history = useHistory();
  const { units = [], company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage("user") || {};
  const [assetData, setAssetData] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [purposes, setPurposes] = useState(null);
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [destinationType, setDestinationType] = useState("supplier");
  const [selectedSupplierType, setSelectedSupplierType] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [suppliers, setSuppliers] = useState(null);
  const [notes, setNotes] = useState("");
  const [returnDate, setReturnDate] = useState(moment);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const types = [
    { id: "non_returnable", name: "Non-Returnable" },
    { id: "returnable", name: "Returnable" },
  ];

  const supplierTypes = [
    { id: "consumable", name: "Consumable Suppliers" },
    { id: "manufacturer", name: "Manufacturer" },
    { id: "service_support", name: "Service Support" },
    { id: "spare_part", name: "Spare Suppliers" },
    { id: "distributor", name: "Supplier" },
  ];

  const params = useParams();

  useEffect(() => {
    if (params.id)
      requestMethods
        .GET({ endpoint: `v3/asset/${companyId}/details/${params?.id}` })
        .then((res) => {
          setAssetData(res);
        });
  }, [params?.id]);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/asset/gate-pass/${companyId}/${assetData?.unit?.id}/get-all-gate-pass-purpose-options`,
      })
      .then((res) => setPurposes(res.purposes));
  }, []);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/supplier/${companyId}/get-all`,

        ...((selectedSupplierType?.id && {
          params: {
            type: selectedSupplierType?.id,
            ignore_paging: true,
          },
        }) || {
          params: {
            ignore_paging: true,
          },
        }),
      })
      .then((res) => setSuppliers(res.data));
  }, [selectedSupplierType?.id]);

  const details = [
    { id: 1, label: "Unit", value: assetData?.unit?.name },
    {
      id: 2,
      label: "From",
      value: assetData?.department_meta[assetData?.owner_department_id]?.name,
    },
    { id: 3, label: "Sub Category", value: assetData?.category?.name },
    { id: 4, label: "Model", value: assetData?.model?.name },
    { id: 5, label: "Asset", value: assetData?.name },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    requestMethods
      .POST({
        endpoint: `v4/asset/gate-pass/${companyId}/${assetData?.unit?.id}/create`,
        body: {
          owner_department_id: assetData?.owner_department_id,
          type: selectedType?.id,
          purpose: selectedPurpose?.id,
          asset_id: assetData?.id,
          expected_return_on: (returnDate && +returnDate.format("x")) || null,
          destination_entity_type: destinationType,
          destination_entity_id: supplier?.id,
          notes,
        },
      })
      .then((res) => {
        setSuccessMessage(res.message);
        setTimeout(() => history.push(`/asset/${assetData?.id}`), 2000);
      })
      .catch((err) => setErrorMessage(err.message));
  };
  useEffect(() => {
    setTimeout(() => setErrorMessage(""), 3000);
  }, [errorMessage]);

  return (
    <PageLayout>
      <PageHeader title="New Gate Pass" />
      <form className={styles.gatepass} onSubmit={handleSubmit}>
        <div className={styles.gatepass__details}>
          {details.map(({ id, label, value }) => (
            <div className={styles.gatepass__child} key={id}>
              <div>{label}</div>
              <div>: {value}</div>
            </div>
          ))}
        </div>

        <AdvancedDropdown
          className={styles["gatepass__dropdown"]}
          value={selectedType}
          options={types}
          onChange={(value) => setSelectedType(value)}
          required={true}
          label="Type"
          clearable
        />
        {selectedType?.id === "returnable" && (
          <div className={styles.gatepass__date}>
            <DatePicker
              label="Expected Return"
              value={returnDate}
              onChange={(value) => setReturnDate(value)}
            />
          </div>
        )}
        <AdvancedDropdown
          className={styles["gatepass__dropdown"]}
          value={selectedPurpose}
          options={purposes?.map((el) => ({
            id: el,
            name: removeUnderScore(el),
          }))}
          label="Purpose"
          onChange={(value) => setSelectedPurpose(value)}
          required={true}
          clearable
        />

        <div className={styles.gatepass__label}>Send To</div>
        <div className={styles.gatepass__radio}>
          <RadioButton
            value={destinationType}
            onChange={() => setDestinationType("supplier")}
            name="supplier"
            label="External Vendor"
          />
          <RadioButton
            value={destinationType}
            onChange={() => setDestinationType("unit")}
            name="unit"
            label="Other Unit"
          />
        </div>

        {destinationType === "supplier" && (
          <AdvancedDropdown
            className={styles["gatepass__dropdown"]}
            label="Vendor Type"
            options={supplierTypes}
            value={selectedSupplierType}
            onChange={(value) => setSelectedSupplierType(value)}
            clearable
          />
        )}
        <AdvancedDropdown
          className={styles["gatepass__dropdown"]}
          label="Select Destination"
          options={(destinationType === "supplier" && suppliers) || units}
          onChange={(value) => setSupplier(value)}
          value={supplier}
          required={true}
          clearable
        />
        <TextInput
          id={"notes"}
          label="Notes"
          value={notes}
          onChange={(value) => setNotes(value)}
        />
        <div className={styles["gatepass__button-container"]}>
          <Button
            className={styles["gatepass__cancel"]}
            onClick={() => history.push(`/asset/${assetData?.id}`)}
            cancelButton
          >
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </div>
        {successMessage && (
          <p className={styles["gatepass__success"]}>{successMessage}</p>
        )}
        {errorMessage && (
          <p className={styles["gatepass__error"]}>{errorMessage}</p>
        )}
      </form>
    </PageLayout>
  );
};

export default GatePass;
