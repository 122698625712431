import React from "react";
import { useMemo } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { FaHandHolding, FaToolbox } from "react-icons/fa";
import { GiTap } from "react-icons/gi";
import { GoSearch } from "react-icons/go";
import { CgBrush } from "react-icons/cg";
import { BiTransfer } from "react-icons/bi";
import Button from "../../components/Button";
import HomeHeader from "../../components/HomeHeader";
import {
  filteredDepartmentList,
  getItemFromLocalStorage,
  history,
} from "../../helpers/utils";
import styles from "./index.module.css";

const CssdHome = () => {
  const { departments = [], privileges = [] } =
    getItemFromLocalStorage("user") || {};

  const custodianDepartments = useMemo(() => {
    return filteredDepartmentList(departments, "custodian", true);
  }, [departments]);

  const tabs = [
    {
      key: "receive",
      label: "Inward",
      onClick: () => history.push("/cssd_home/inward"),
      icon: <FaHandHolding />,
      show: privileges["cssdKitTransactionView"],
    },
    {
      key: "inspect",
      label: "Inspect",
      onClick: () => history.push("/cssd_home/inspect"),
      icon: <GoSearch />,
      show: privileges["cssdKitInspectView"],
    },
    {
      key: "wash",
      label: "Wash",
      onClick: () => {
        history.push("/cssd_home/wash");
      },
      icon: <GiTap />,
      show: privileges["cssdKitWashView"],
    },
    {
      key: "pack",
      label: "Pack",
      onClick: () => history.push("/cssd_home/pack"),
      icon: <FaToolbox />,
      show: privileges["cssdKitPackingView"],
    },
    {
      key: "sterilize",
      label: "Sterilize",
      onClick: () => history.push("/cssd_home/sterilize"),
      icon: <CgBrush />,
      show: privileges["cssdKitSterilizeView"],
    },
    {
      key: "dispatch",
      label: "Dispatch",
      onClick: () => history.push("/cssd_home/dispatch"),
      icon: <BiTransfer />,
      show: privileges["cssdKitTransactionView"],
    },
  ];

  return (
    <div className={styles["cssd-home"]}>
      <div className={styles["cssd-home__header"]}>
        <HomeHeader />
      </div>
      <div className={styles["cssd-home__main-section"]}>
        <div className={styles["cssd-home__tab-container"]}>
          {tabs.map(
            (tab) =>
              custodianDepartments[0].allowed_process.includes(tab.key) &&
              tab.show && (
                <Button
                  key={tab.key}
                  className={styles["cssd-home__tab"]}
                  onClick={tab.onClick}
                >
                  <div className={styles["cssd-home__icon"]}>{tab.icon}</div>
                  <div className={styles["cssd-home__label"]}>{tab.label}</div>
                </Button>
              )
          )}
        </div>
        <Button
          type="button"
          className={styles["cssd-home__back-button"]}
          onClick={() => history.push("/home")}
          styleReverse
        >
          <AiOutlineHome size={20} />
          {"Home"}
        </Button>
      </div>
    </div>
  );
};

export default CssdHome;
