import React from "react";
import ReactDatePicker from "react-datetime";
import moment from "moment";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";
import "react-datetime/css/react-datetime.css";

export const DatePicker = (props) => {
  const { label, className, datePickerClassName, disabled, viewMode, value, onChange, renderInput, name, isValidate } =
    props;

  const handleChange = (value) => {
    if (isValidate) {
      if (+value.format("x") > +moment().format("x")) {
        onChange(value);
      }
    } else {
      onChange(value);
    }
  };

  const validateCalender = (currentState) => {
    if (isValidate) {
      return currentState.isAfter(moment().subtract(1, "d"));
    }
  };

  return (
    <div className={joinClass(styles.datePickerContainer, className)}>
      <label className={styles["datePickerContainer__label"]}>{label}</label>
      <ReactDatePicker
        dateFormat={viewMode !== "time" ? "D/MM/YYYY" : false}
        value={value}
        onChange={(value) => handleChange(value)}
        className={joinClass(datePickerClassName, styles.datePicker)}
        closeOnSelect
        inputProps={{
          placeholder: "Select Date",
          readOnly: true,
          disabled,
          name,
        }}
        locale="en"
        renderInput={renderInput}
        isValidDate={isValidate && validateCalender}
      />
    </div>
  );
};
