import React, { Fragment, useEffect, useState } from "react";
import styles from "./index.module.css";
import PageLayout from "../../components/PageLayout";
import PageHeader from "../../components/PageHeader";
import { useParams } from "react-router";
import requestMethods from "../../helpers/request";
import { getItemFromLocalStorage } from "../../helpers/utils";
import { AiFillCamera } from "react-icons/ai";
import Camera from "../../components/Camera";
import NewModal from "../../components/NewModal";
import Button from "../../components/Button";
import { permissions } from "../../helpers/permission";
import Toaster from "../../components/Toaster";
import { BsImages } from "react-icons/bs";

const statusMap = {
  returned: "Closed",
  approved: "Approved",
  cancelled: "Cancelled",
  pending: "Created",
  dispatched: "Dispatched",
};

const GatePassImageUpload = () => {
  const { gatePassId } = useParams();

  const {
    company: { id: companyId = "" } = {},
    units = [],
    privileges = {},
  } = getItemFromLocalStorage("user") || {};

  const [gatePassDetails, setGatePassDetails] = useState({});
  const [isOpenCamera, toggleOpenCamera] = useState(false);
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchGatepPassDetails();
  }, []);

  const fetchGatepPassDetails = () => {
    if (gatePassId) {
      requestMethods
        .GET({
          endpoint: `v4/asset/gate-pass/${companyId}/${units[0]?.id}/details/${gatePassId}`,
        })
        .then((res) => {
          setGatePassDetails(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [isOpenImage, toggleOpenImage] = useState(false);

  return (
    <Fragment>
      {message?.text && <Toaster message={message} setMessage={setMessage} />}
      {isOpenImage && (
        <NewModal isOpen={isOpenImage} onCancel={() => toggleOpenImage(false)}>
          <div className={styles["gate-pass-image-upload__modal-title"]}>
            {"Images"}
          </div>
          <div className={styles["gate-pass-image-upload__image-preview"]}>
            {gatePassDetails?.urls?.map((url, index) => (
              <img src={url?.package_download_url} key={index} />
            ))}
          </div>
        </NewModal>
      )}

      {image && (
        <NewModal isOpen={!!image} onCancel={() => setImage(null)}>
          <img src={URL.createObjectURL(image)} />
          <div className={styles["gate-pass-image-upload__button-container"]}>
            <Button cancelButton onClick={() => setImage(null)}>
              {"Cancel"}
            </Button>
            <Button
              onClick={() => {
                const file = new File([image], "gatePassAssetImage.jpeg", {
                  type: "image/jpeg",
                });

                const formData = new FormData();
                formData.append("file", file);

                requestMethods
                  .POST({
                    endpoint: `v4/media/${companyId}/${units[0]?.id}/${gatePassId}/multiple_images`,
                    body: formData,
                  })
                  .then((res) => {
                    console.log(res);
                    setImage(null);
                    setMessage({
                      text: "Gate pass image uploaded successfully",
                      type: "success",
                    });
                    fetchGatepPassDetails();
                  })
                  .catch((error) => {
                    setMessage({
                      text: "Gate pass image uploaded failed",
                      type: "error",
                    });
                    console.log(error);
                  });
              }}
            >
              {"Upload"}
            </Button>
          </div>
        </NewModal>
      )}
      {!isOpenCamera ? (
        <div className={styles["gate-pass-image-upload"]}>
          <PageLayout>
            <PageHeader title="Gate Pass Details" />
            <div className={styles["gate-pass-image-upload__details"]}>
              <div>
                <label>{"ID"}</label>
                <label>{gatePassDetails?.gate_pass_number}</label>
              </div>
              <div>
                <label>{"Created By"}</label>
                <label>{gatePassDetails?.created_by?.name}</label>
              </div>
              <div>
                <label>{"Send To"}</label>
                <label>{gatePassDetails?.sent_to?.name}</label>
              </div>
              <div>
                <label>{"Purpose"}</label>
                <label>{gatePassDetails?.purpose}</label>
              </div>
              <div>
                <label>{"Status"}</label>
                <label>{statusMap[gatePassDetails?.gate_pass_status]}</label>
              </div>
              {privileges[permissions.GATE_PASS_IMAGE_UPLOAD] && (
                <div
                  className={styles["gate-pass-image-upload__camera-container"]}
                >
                  <label>{"Upload Image"}</label>
                  <div
                    className={styles["gate-pass-image-upload__camera"]}
                    onClick={() => toggleOpenCamera(true)}
                  >
                    <AiFillCamera size={20} color="#1aa555" />
                  </div>
                </div>
              )}
              {gatePassDetails?.urls?.length > 0 && (
                <div onClick={() => toggleOpenImage(true)}>
                  <label>{"Uploaded Image"}</label>
                  <BsImages />
                </div>
              )}
            </div>
            <div className={styles["gate-pass-image-upload__asset-details"]}>
              <div className={styles["gate-pass-image-upload__title"]}>
                {"Asset Details"}
              </div>
              {gatePassDetails?.assets?.map((asset) => (
                <div
                  key={asset?.id}
                  className={styles["gate-pass-image-upload__asset"]}
                >
                  <div>
                    <label>{"Sub Category"}</label>
                    <label>{asset?.category?.name}</label>
                  </div>
                  <div>
                    <label>{"Model"}</label>
                    <label>{asset?.model?.name}</label>
                  </div>
                  <div>
                    <label>{"Asset"}</label>
                    <label>
                      {asset?.si_no + ", " + asset?.code + " - " + asset?.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </PageLayout>
        </div>
      ) : (
        <Camera onClose={() => toggleOpenCamera(false)} setImage={setImage} />
      )}
    </Fragment>
  );
};

export default GatePassImageUpload;
