import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import requestMethods from "../../../helpers/request";
import {
  filteredDepartmentList,
  getItemFromLocalStorage,
  joinClass,
  removeUnderScore,
} from "../../../helpers/utils";
import { GiTap } from "react-icons/gi";
import NewModal from "../../../components/NewModal";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import Pagination from "../../../components/Pagination";
import moment from "moment";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import Loader from "../../../components/Loader";
import { GrCompliance } from "react-icons/gr";
import styles from "./index.module.css";

const Wash = () => {
  const {
    company: { id: companyId } = {},
    units = [],
    departments = [],
    id: userId,
  } = getItemFromLocalStorage("user") || {};

  const filteredCustodianDepartments = useMemo(() => {
    return filteredDepartmentList(departments, "custodian", true);
  }, []);

  const [tab, setTab] = useState("pending");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [cssdKits, setCssdKits] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isStart, toggleStart] = useState(false);
  const [row, setRow] = useState({});
  const [lineNumber, setLineNumber] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [washList, setWashList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [nextProcess, setNextProcess] = useState({
    id: "",
    process: "",
    status: "",
  });
  const [isNextStep, toggleNextStep] = useState(false);
  const [isLoading, setLoading] = useState("none");
  const [entityType, setEntityType] = useState({
    id: "cssd_kit",
    label: "KITs",
  });
  const [instruments, setInstruments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [originArea, setOriginArea] = useState(null);
  const [startOrComplete, toggleStartOrComplete] = useState(false);

  useEffect(() => {
    setLoading("loading");

    if (tab === "pending" && originArea?.id) {
      if (entityType.id === "cssd_kit") {
        requestMethods
          .GET({
            endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
            params: {
              custodianDepartmentId: filteredCustodianDepartments[0].id,
              page,
              size: rowsPerPage,
              status: "awaiting_wash",
              location: originArea?.id,
            },
          })
          .then((res) => {
            if (res.status) {
              setLoading("loaded");
              setTotalCount(res.total_count);
              setCssdKits(res.cssd_kits);
            }
          })
          .catch((err) => setErrorMessage(err.message));
      } else {
        requestMethods
          .GET({
            endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
            params: {
              custodian_department_id: departments[0].id,
              ignore_paging: false,
              cssdStatus: "awaiting_wash",
              is_kit_assigned: "unassigned",
              home_location_id: originArea?.id,
              page,
              size: rowsPerPage,
            },
          })
          .then((res) => {
            setInstruments(res.assets);
            setLoading("loaded");
            setTotalCount(res.total_count);
          })
          .catch((err) => setErrorMessage(err.message));
      }
    } else if (tab === "in_process" || tab === "completed") {
      requestMethods
        .GET({
          endpoint: `v4/cssd-kit/wash/${companyId}/${units[0].id}/get-all`,
          params: {
            page,
            size: rowsPerPage,
            departmentId: filteredCustodianDepartments[0].id,
            status: (tab === "in_process" && "in_progress") || "completed",
            entityType: entityType?.id,
          },
        })
        .then((res) => {
          if (res.status) {
            setLoading("loaded");
            setWashList(res.cssd_kit_wash_list);
            setTotalCount(res.total_count);
          }
        })
        .catch((error) => setErrorMessage(error.message));
    }
  }, [
    page,
    rowsPerPage,
    filteredCustodianDepartments,
    tab,
    entityType,
    originArea,
  ]);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/area/${companyId}/${units[0].id}/get-areas`,
        params: { ignore_paging: true },
      })
      .then((res) => {
        setAreas(res.areas);
      })
      .catch((err) => setErrorMessage(err.message));
  }, []);

  useEffect(() => {
    setOriginArea(handleOriginAreas()[0]);
  }, [areas]);

  const handleOriginAreas = () => {
    const newAreaList = [];

    departments[0].allowed_areas?.forEach((areaId) => {
      if (areas.find((area) => area.id === areaId)) {
        newAreaList.push(areas.find((area) => area.id === areaId));
      }
    });

    return newAreaList;
  };
  useEffect(() => {
    let nextProcess = null;
    for (let i = 0; i < checkBoxList.length; i++) {
      if (
        filteredCustodianDepartments[0]?.allowed_process?.includes(
          checkBoxList[i].process
        )
      ) {
        nextProcess = checkBoxList[i];
        break;
      }
    }
    setNextProcess(nextProcess);
  }, []);

  const tabList = [
    {
      key: "pending",
      label: "Pending",
      disabled: false,
    },
    {
      key: "in_process",
      label: "In Process",
      disabled: false,
    },
    {
      key: "completed",
      label: "Completed",
      disabled: false,
    },
  ];

  const handleStart = (e) => {
    e.preventDefault();
    toggleStartOrComplete(true);

    requestMethods
      .POST({
        endpoint: `v4/cssd-kit/wash/${companyId}/${units[0].id}/create`,
        body: {
          entity_id: row.id,
          started_by: userId,
          started_on: +moment().format("x"),
          line_number: lineNumber,
          washed_department_id: departments[0].id,
          entity_type: entityType?.id,
        },
      })
      .then((res) => {
        if (res.status) {
          toggleStartOrComplete(false);
          setSuccessMessage("Wash Started");
          setLineNumber("");

          setTimeout(() => {
            if (entityType.id === "cssd_kit") {
              requestMethods
                .PUT({
                  endpoint: `v4/cssd/kit/${companyId}/${units[0].id}/update_status`,
                  body: {
                    cssd_kit_ids: [row.id],
                    next_phase: "wash",
                    kit_status: "under_wash",
                  },
                })
                .then((res) => {
                  if (res.status) {
                    toggleStart(false);
                    setLoading("loading");
                    setSuccessMessage("");
                    requestMethods
                      .GET({
                        endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
                        params: {
                          custodianDepartmentId:
                            filteredCustodianDepartments[0].id,
                          page,
                          size: rowsPerPage,
                          status: "awaiting_wash",
                        },
                      })
                      .then((res) => {
                        setLoading("loaded");
                        setTotalCount(res.total_count);
                        setCssdKits(res.cssd_kits);
                      })
                      .catch((err) => {
                        setErrorMessage(err.message);
                      });
                  }
                })
                .catch((error) => {
                  setErrorMessage(error.message);
                });
            } else {
              requestMethods
                .PUT({
                  endpoint: `/v3/asset/${companyId}/update_cssd_status/${row.id}`,
                  body: {
                    cssd_status: "under_wash",
                    unit_id: units[0].id,
                  },
                })
                .then((res) => {
                  if (res.status) {
                    toggleStart(false);
                    setLoading("loading");
                    setSuccessMessage("");
                    requestMethods
                      .GET({
                        endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
                        params: {
                          custodian_department_id: departments[0].id,
                          ignore_paging: false,
                          cssdStatus: "awaiting_wash",
                          is_kit_assigned: "unassigned",
                          home_location_id: originArea?.id,
                          page,
                          size: rowsPerPage,
                        },
                      })
                      .then((res) => {
                        setInstruments(res.assets);
                        setLoading("loaded");
                        setTotalCount(res.total_count);
                      })
                      .catch((err) => {
                        setErrorMessage(err.message);
                      });
                  }
                });
            }
          }, 1000);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        toggleStartOrComplete(false);
      });
  };

  const handleComplete = (e) => {
    e.preventDefault();
    toggleStartOrComplete(true);

    requestMethods
      .PUT({
        endpoint: `v4/cssd-kit/wash/${companyId}/${units[0].id}/update/${row.washId}`,
        body: {
          completed_by: userId,
          completed_on: +moment().format("x"),
          kit_status: nextProcess.status,
          next_phase: nextProcess.process,
        },
      })
      .then((res) => {
        if (res.status) {
          toggleStartOrComplete(false);
          setSuccessMessage("Wash Completed");
          setTimeout(() => {
            toggleStart(false);
            setLoading("loading");
            setSuccessMessage("");
            requestMethods
              .GET({
                endpoint: `v4/cssd-kit/wash/${companyId}/${units[0].id}/get-all`,
                params: {
                  page,
                  size: rowsPerPage,
                  departmentId: filteredCustodianDepartments[0].id,
                  status: "in_progress",
                  entityType: entityType?.id,
                },
              })
              .then((res) => {
                if (res.status) {
                  setLoading("loaded");
                  setWashList(res.cssd_kit_wash_list);
                  setTotalCount(res.total_count);
                }
              })
              .catch((error) => {
                setErrorMessage(error.message);
              });
          }, 1000);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const checkBoxList = [
    {
      key: 1,
      process: "pack",
      label: "Pack",
      status: "awaiting_pack",
      id: "pack",
    },
    {
      key: 2,
      process: "wash",
      label: "wash",
      status: "awaiting_wash",
      id: "wash",
    },
    {
      key: 3,
      process: "dispatch",
      label: "Dispatch",
      status: "awaiting_dispatch",
      id: "dispatch",
    },
  ];

  const handleWashList = () => {
    const type = (entityType?.id === "cssd_kit" && "cssd_kit") || "asset";

    if (tab === "in_process" || tab === "completed") {
      return (
        <div className={styles["wash__table"]}>
          <div
            className={joinClass(
              styles["wash__columns-container"],
              tab === "completed" && styles["wash__three-columns"]
            )}
          >
            <div>{"Wash ID"}</div>
            <div>{"Code"}</div>
            <div>{"Name"}</div>
          </div>
          <div
            className={joinClass(
              styles["wash__row-container"],
              tab === "completed" && styles["wash__three-row"]
            )}
          >
            {washList.map((wash) => (
              <div key={wash.id}>
                <div>{wash.wash_number}</div>
                <div>{wash[type]?.code}</div>
                <div className={styles["wash__split-row"]}>
                  <div>{wash[type]?.name}</div>
                  {tab === "in_process" && (
                    <div
                      onClick={() => {
                        toggleStart(true);
                        setRow({
                          washNumber: wash.wash_number,
                          washId: wash.id,
                          ...wash[type],
                        });
                      }}
                      className={styles["wash__icon"]}
                    >
                      <GrCompliance />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles["wash"]}>
      <PageHeader themeHeader title={"Wash"} returnHome={"/cssd_home"} />
      <NewModal
        isOpen={isStart}
        onCancel={() => {
          toggleStart(false);
          setLineNumber("");
        }}
      >
        <form
          className={styles["wash__modal"]}
          onSubmit={(tab === "pending" && handleStart) || handleComplete}
        >
          {!successMessage && (
            <>
              <div className={styles["wash__preview"]}>
                {tab !== "pending" && (
                  <div>
                    <div>{"Wash ID"}</div>
                    <div>{row.washNumber}</div>
                  </div>
                )}
                <div>
                  <div>{"Code"}</div>
                  <div>{row.code}</div>
                </div>
                <div>
                  <div>{"Name"}</div>
                  <div>{row.name}</div>
                </div>
              </div>
              {tab === "pending" && (
                <TextInput
                  label={"Line Number"}
                  value={lineNumber}
                  onChange={(value) => setLineNumber(value)}
                  required
                  autoFocus={true}
                />
              )}
              {tab === "in_process" && (
                <div className={styles["radio-container"]}>
                  <div className={styles["radio-container__process"]}>
                    <label>{"Next Process :"}</label>
                    <label>{nextProcess.label}</label>
                  </div>
                  <div className={styles["radio-container__check"]}>
                    <u onClick={() => toggleNextStep(!isNextStep)}>
                      {"Change ?"}
                    </u>
                  </div>
                  {isNextStep && (
                    <AdvancedDropdown
                      options={checkBoxList.filter((item) =>
                        filteredCustodianDepartments[0]?.allowed_process?.includes(
                          item.process
                        )
                      )}
                      value={nextProcess}
                      onChange={(value) => setNextProcess(value)}
                    />
                  )}
                </div>
              )}
              <div className={styles["wash__button-container"]}>
                <Button
                  onClick={() => {
                    toggleStart(false);
                    setLineNumber("");
                  }}
                  cancelButton
                  type="button"
                  className={styles["wash__button"]}
                >
                  {"Cancel"}
                </Button>

                <Button
                  type="submit"
                  className={styles["wash__button"]}
                  disabled={startOrComplete}
                >
                  {(tab === "pending" && "Start") || "Complete"}
                </Button>
              </div>
            </>
          )}
          {successMessage && (
            <label className={styles["wash__message"]}>{successMessage}</label>
          )}
        </form>
      </NewModal>
      <div className={styles["wash__main-section"]}>
        <div className={styles["wash__filter"]}>
          <AdvancedDropdown
            label={"Area"}
            value={originArea}
            options={handleOriginAreas()}
            onChange={(value) => setOriginArea(value)}
            disabled={handleOriginAreas().length === 1}
          />
          <AdvancedDropdown
            label={"Entity Type"}
            options={[
              { id: "cssd_kit", label: "KITs" },
              { id: "asset", label: "Assets" },
            ]}
            value={entityType}
            onChange={(value) => {
              setEntityType(value);
            }}
          />
        </div>
        <div className={styles["wash__tab-container"]}>
          {tabList.map((item) => (
            <div
              id={item.key}
              key={item.key}
              className={joinClass(
                styles["wash__tab-container__tab"],
                item.disabled && styles["wash__tab-container__disabled"],
                tab === item.key && styles["wash__tab-container__active"]
              )}
              onClick={() => {
                if (!item.disabled) {
                  setTab(item.key);
                  setPage(1);
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        {(totalCount > 0 && isLoading === "loaded" && (
          <div className={styles["wash__sub-section"]}>
            {tab === "pending" && entityType?.id === "cssd_kit" && (
              <div className={styles["wash__table"]}>
                <div
                  className={joinClass(
                    styles["wash__columns-container"],
                    styles["wash__two-columns"]
                  )}
                >
                  <div>{"Code"}</div>
                  <div>{"Name"}</div>
                </div>
                <div
                  className={joinClass(
                    styles["wash__row-container"],
                    styles["wash__two-row"]
                  )}
                >
                  {cssdKits.map((kit) => (
                    <div key={kit.id}>
                      <div>{kit.code}</div>
                      <div className={styles["wash__split-row"]}>
                        <div>{kit.name}</div>
                        <div
                          onClick={() => {
                            toggleStart(true);
                            setRow(kit);
                          }}
                          className={styles["wash__icon"]}
                        >
                          <GiTap />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {tab === "pending" && entityType?.id === "asset" && (
              <div className={styles["wash__table"]}>
                <div
                  className={joinClass(
                    styles["wash__columns-container"],
                    styles["wash__two-columns"]
                  )}
                >
                  <div>{"Code"}</div>
                  <div>{"Name"}</div>
                </div>
                <div
                  className={joinClass(
                    styles["wash__row-container"],
                    styles["wash__two-row"]
                  )}
                >
                  {instruments.map((instrument) => (
                    <div key={instrument.id}>
                      <div>{instrument.code}</div>
                      <div className={styles["wash__split-row"]}>
                        <div>{instrument.name}</div>
                        <div
                          onClick={() => {
                            toggleStart(true);
                            setRow(instrument);
                          }}
                          className={styles["wash__icon"]}
                        >
                          <GiTap />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {handleWashList()}
            {totalCount > 0 && (
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                setPage={setPage}
                listCount={
                  (tab === "pending" && cssdKits.length) || washList.length
                }
              />
            )}
          </div>
        )) ||
          (isLoading === "loading" && <Loader />) || (
            <div className={styles["wash__error-message"]}>
              {(errorMessage && removeUnderScore(errorMessage)) ||
                "No data available"}
            </div>
          )}
      </div>
    </div>
  );
};

export default Wash;
