import React from "react";
import Button from "../../../../../components/Button";
import { CheckBox } from "../../../../../components/CheckBox";
import NewModal from "../../../../../components/NewModal";
import TextInput from "../../../../../components/TextInput";
import styles from "./index.module.css";

const SpongeValidation = ({
  isOpen,
  handleClearModal,
  packet,
  validateSponge,
  toggleValidateSponge,
  spongeId,
  setSpongeId,
  setMessage,
  setPacketList,
  spongeRef,
  choice,
  setChoice,
  setPacket,
  packetList,
}) => {
  const handlePacketSpongeCodes = (value) => {
    const newArray = packet.sponge_codes
      .filter((sponge) => sponge.type === "current")
      .map((sponge) => {
        return { ...sponge, available: value };
      });

    setPacket({ ...packet, unknown_sponges: [], sponge_codes: newArray });
  };

  return (
    <NewModal isOpen={isOpen} onCancel={handleClearModal}>
      <div className={styles["surgery-create-modal"]}>
        <div className={styles["surgery-create-modal__detail"]}>
          <div className={styles["surgery-create-modal__first"]}>
            <div>{"Pack ID"}</div>
            <div>{`: ${packet?.code}`}</div>
          </div>
          <div className={styles["surgery-create-modal__second"]}>
            <div>{"Sponge IDs"}</div>
            <div>
              {packet?.sponge_codes?.map((item) => (
                <li
                  key={item?.id}
                  className={
                    styles[
                      `surgery-create-modal__${
                        (validateSponge && item?.available && "available") ||
                        (validateSponge && !item?.available && "not-available")
                      }`
                    ]
                  }
                >
                  <span>{item?.id}</span>
                </li>
              ))}
            </div>
          </div>
          {packet?.unknown_sponges?.length > 0 && (
            <div>
              <div>{"Unknown Sponge IDs"}</div>
              <div>
                {packet.unknown_sponges.map((item) => (
                  <li key={item}>
                    <span>{item}</span>
                  </li>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles["surgery-create-modal__input-container"]}>
          <CheckBox
            id="validateSponge"
            label={"Validate Sponge"}
            checked={validateSponge}
            onChange={(e) => {
              if (e.target.checked) handlePacketSpongeCodes(false);
              else handlePacketSpongeCodes(true);

              toggleValidateSponge(e.target.checked);
            }}
          />
          {validateSponge && (
            <TextInput
              value={spongeId}
              onChange={(e) => setSpongeId(e)}
              label={"Sponge ID"}
              type="text"
              autoFocus
              inputRef={spongeRef}
            />
          )}
          {choice && (
            <div className={styles["surgery-create-modal__choice"]}>
              <Button
                type="button"
                onClick={() => {
                  if (!packet?.unknown_sponges?.includes(spongeId)) {
                    setPacket({
                      ...packet,
                      unknown_sponges: [...packet?.unknown_sponges, spongeId],
                    });
                  } else {
                    setMessage({ type: "error", text: "Already added" });
                  }
                  setChoice(false);
                  setSpongeId("");
                  spongeRef.current.focus();
                }}
                cancelButton
              >
                {"Add to unknown sponge"}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  if (!packet?.unknown_sponges.includes(spongeId)) {
                    setPacket({
                      ...packet,
                      sponge_codes: [
                        ...packet?.sponge_codes,
                        {
                          id: spongeId,
                          available: null,
                          type: "unknown",
                        },
                      ],
                    });
                  } else {
                    setMessage({ type: "error", text: "Already added" });
                  }
                  setChoice(false);
                  setSpongeId("");
                  spongeRef.current.focus();
                }}
                cancelButton
              >
                {"Add to sponge"}
              </Button>
            </div>
          )}
        </div>
        <div className={styles["surgery-create-modal__button-container"]}>
          <Button type="button" onClick={handleClearModal}>
            {"Back"}
          </Button>
          <Button
            type="button"
            onClick={() => {
              setPacketList([
                ...packetList,
                {
                  ...packet,
                  validate: (validateSponge && true) || false,
                  newPacket: true,
                  sponge_codes: packet.sponge_codes.filter(
                    (sponge) => sponge.available || sponge.type === "unknown"
                  ),
                  missed_sponges: packet.sponge_codes.filter(
                    (sponge) => !sponge.available && sponge.type !== "unknown"
                  ),
                },
              ]);
              handleClearModal();
            }}
            disabled={choice}
          >
            {"Add"}
          </Button>
        </div>
      </div>
    </NewModal>
  );
};

export default SpongeValidation;
