import React, { useEffect, useState } from "react";
import moment from "moment";
import PageHeader from "../../../components/PageHeader";
import Pagination from "../../../components/Pagination";
import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  history,
  joinClass,
  removeUnderScore,
} from "../../../helpers/utils";
import { GoPlus } from "react-icons/go";
import Loader from "../../../components/Loader";
import styles from "./index.module.css";

const SurgicalSpongePacking = () => {
  const {
    units = [],
    company: { id: companyId } = {},
    privileges = [],
  } = getItemFromLocalStorage("user") || {};

  const [tab, setTab] = useState("packed");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState("none");
  const [message, setMessage] = useState("");
  const [packingList, setPackingList] = useState([]);

  useEffect(() => {
    setLoading("loading");
    requestMethods
      .GET({
        endpoint: `v4/surgery/sponge-packet/${companyId}/${units[0].id}/get-all`,
        params: {
          status: tab,
          sort: -1,
          page: page,
          size: rowsPerPage,
        },
      })
      .then((res) => {
        if (res.status) {
          setPackingList(res.surgical_sponge_packet_responses);
          setTotalCount(res.total_count);
        }
        setLoading("loaded");
      })
      .catch((error) => {
        setLoading("notLoaded");
        setMessage(error.message);
      });
  }, [tab, rowsPerPage, page]);

  const tabList = [
    {
      key: "packed",
      label: "Packed",
      disabled: false,
    },
    {
      key: "in_use",
      label: "In Use",
      disabled: false,
    },
    {
      key: "consumed",
      label: "Consumed",
      disabled: false,
    },
  ];

  return (
    <div className={styles["surgical-sponge-packing"]}>
      <PageHeader
        themeHeader
        title="Packing"
        returnHome="/surgical_sponge_track_home"
      />
      <div className={styles["surgical-sponge-packing__main-section"]}>
        {privileges["surgicalSpongePacketManage"] && (
          <div className={styles["surgical-sponge-packing__create"]}>
            <div
              onClick={() =>
                history.push("/surgical_sponge_track_home/packing/create")
              }
            >
              <GoPlus />
              <label>{"Create"}</label>
            </div>
          </div>
        )}
        <div className={styles["surgical-sponge-packing__tab-container"]}>
          {tabList.map((item) => (
            <div
              id={item.key}
              key={item.key}
              className={joinClass(
                styles["surgical-sponge-packing__tab-container__tab"],
                item.disabled &&
                  styles["surgical-sponge-packing__tab-container__disabled"],
                tab === item.key &&
                  styles["surgical-sponge-packing__tab-container__active"]
              )}
              onClick={() => {
                if (!item.disabled) {
                  setTab(item.key);
                  setPage(1);
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        {(totalCount > 0 && isLoading === "loaded" && (
          <div className={styles["surgical-sponge-packing__sub-section"]}>
            <div className={styles["surgical-sponge-packing__table"]}>
              <div
                className={styles["surgical-sponge-packing__columns-container"]}
              >
                <div>{"Pack ID"}</div>
                <div>{"# of sponges"}</div>
                <div>{"Packed By"}</div>
              </div>
              <div className={styles["surgical-sponge-packing__row-container"]}>
                {packingList.map((pack) => (
                  <div key={pack.id}>
                    <div>{pack.code}</div>
                    <div>{pack.sponge_codes.length}</div>
                    <div>
                      <div> {pack.created_by.name}</div>
                      <div className={styles["surgical-sponge-packing__date"]}>
                        {moment(pack.created_on).format("Do MM YYYY hh:mm A")}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {totalCount > 0 && (
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                setPage={setPage}
                listCount={packingList.length}
              />
            )}
          </div>
        )) ||
          (isLoading === "loading" && <Loader />) || (
            <div className={styles["surgical-sponge-packing__error-message"]}>
              {(message && removeUnderScore(message)) || "No data available"}
            </div>
          )}
      </div>
    </div>
  );
};

export default SurgicalSpongePacking;
