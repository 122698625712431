import React, { useRef, useEffect, useState } from "react";
import Webcam from "react-webcam";
import styles from "./index.module.css";
import { BsBackspaceFill } from "react-icons/bs";

const Camera = ({ onClose, setImage }) => {
  const webcamRef = useRef(null);
  const [cameraDimensions, setCameraDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const updateCameraDimensions = () => {
      const { innerWidth, innerHeight } = window;
      setCameraDimensions({ width: innerWidth, height: innerHeight });
    };

    updateCameraDimensions();

    window.addEventListener("resize", updateCameraDimensions);

    return () => {
      window.removeEventListener("resize", updateCameraDimensions);
    };
  }, []);

  const captureImage = () => {
    const image = webcamRef.current.getScreenshot();

    // Convert the image data to a Blob
    const byteCharacters = atob(image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    setImage(blob);
    onClose();
  };

  return (
    <div className={styles["webcam"]}>
      <Webcam
        audio={false}
        mirrored={true}
        ref={webcamRef}
        width={cameraDimensions.width}
        height={cameraDimensions.height * 0.99}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: "environment",
        }}
      />

      <div className={styles["webcam__back"]}>
        <BsBackspaceFill size={30} color="#fff" onClick={onClose} />
      </div>
      <div className={styles["webcam__capture"]} onClick={captureImage} />
    </div>
  );
};

export default Camera;
