import React from "react";
import Button from "../../components/Button";
import HomeHeader from "../../components/HomeHeader";
import { AiOutlineHome } from "react-icons/ai";
import { GoPackage } from "react-icons/go";
import { FiScissors } from "react-icons/fi";
import { getItemFromLocalStorage, history } from "../../helpers/utils";
import styles from "./index.module.css";

const SurgicalSpongeTrackHome = () => {
  const { privileges = [] } = getItemFromLocalStorage("user") || {};

  const tabs = [
    {
      key: "packing",
      label: "Packing",
      onClick: () => history.push("/surgical_sponge_track_home/packing"),
      icon: <GoPackage />,
      show:
        privileges["surgicalSpongePacketView"] ||
        privileges["surgicalSpongePacketManage"],
    },
    {
      key: "ot",
      label: "OT",
      onClick: () => history.push("/surgical_sponge_track_home/ot"),
      icon: <FiScissors />,
      show: privileges["surgeryView"] || privileges["surgeryManage"],
    },
  ];

  return (
    <div className={styles["surgical-sponge-track-home"]}>
      <div className={styles["surgical-sponge-track-home__header"]}>
        <HomeHeader />
      </div>
      <div className={styles["surgical-sponge-track-home__main-section"]}>
        <div className={styles["surgical-sponge-track-home__tab-container"]}>
          {tabs.map(
            (tab) =>
              tab.show && (
                <Button
                  key={tab.key}
                  className={styles["surgical-sponge-track-home__tab"]}
                  onClick={tab.onClick}
                >
                  <div className={styles["surgical-sponge-track-home__icon"]}>
                    {tab.icon}
                  </div>
                  <div className={styles["surgical-sponge-track-home__label"]}>
                    {tab.label}
                  </div>
                </Button>
              )
          )}
        </div>
        <Button
          type="button"
          className={styles["surgical-sponge-track-home__back-button"]}
          onClick={() => history.push("/home")}
          styleReverse
        >
          <AiOutlineHome size={20} />
          {"Home"}
        </Button>
      </div>
    </div>
  );
};

export default SurgicalSpongeTrackHome;
