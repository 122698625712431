import React, { useEffect, useState } from "react";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import Button from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import PageHeader from "../../../components/PageHeader";
import Pagination from "../../../components/Pagination";
import TextInput from "../../../components/TextInput";
import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  history,
  joinClass,
  removeUnderScore,
} from "../../../helpers/utils";
import styles from "./index.module.css";

const Pack = () => {
  const {
    company: { id: companyId } = {},
    units = [],
    departments = [],
  } = getItemFromLocalStorage("user") || {};

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [errorMessage, setErrorMessage] = useState("");
  const [cssdKits, setCssdKits] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tab, setTab] = useState("kits");
  const [originArea, setOriginArea] = useState(null);
  const [areas, setAreas] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [isPackAsset, togglePackAsset] = useState(false);
  const [row, setRow] = useState(null);
  const [condition, setCondition] = useState("");
  const [availability, setAvailability] = useState(false);
  const [isNextStep, toggleNextStep] = useState(false);
  const [complete, toggleComplete] = useState(false);
  const [packingMaterials, setPackingMaterials] = useState(null);
  const [material, setMaterial] = useState(null);
  const [wrapCount, setWrapCount] = useState("");

  useEffect(() => {
    if (originArea?.id && tab === "kits") {
      requestMethods
        .GET({
          endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
          params: {
            custodianDepartmentId: departments[0].id,
            page,
            size: rowsPerPage,
            status: "awaiting_pack",
            location: originArea?.id,
          },
        })
        .then((res) => {
          if (tab === "kits") setTotalCount(res.total_count);
          setCssdKits(res.cssd_kits);
        })
        .catch((err) => setErrorMessage(err.message));
    }
  }, [page, rowsPerPage, originArea, tab]);

  useEffect(() => {
    if (originArea?.id && tab === "instruments") {
      requestMethods
        .GET({
          endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
          params: {
            custodian_department_id: departments[0].id,
            ignore_paging: false,
            cssdStatus: "awaiting_pack",
            is_kit_assigned: "unassigned",
            home_location_id: originArea?.id,
            page,
            size: rowsPerPage,
          },
        })
        .then((res) => {
          setInstruments(res.assets);
          if (tab === "instruments") setTotalCount(res.total_count);
        })
        .catch((err) => setErrorMessage(err.message));
    }
  }, [tab, page, rowsPerPage, originArea]);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/area/${companyId}/${units[0].id}/get-areas`,
        params: { ignore_paging: true },
      })
      .then((res) => {
        setAreas(res.areas);
      })
      .catch((err) => setErrorMessage(err.message));
  }, []);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/cssd/sterilization_rule/${companyId}/${units[0].id}/get-all-cssd-packing-material`,
      })
      .then((res) => {
        setPackingMaterials(res.packing_material);
      })
      .catch((error) => setErrorMessage(error.message));
  }, []);

  const kitColumns = ["code", "name"];

  const tabList = [
    {
      key: "kits",
      label: "KITs",
      disabled: false,
    },
    {
      key: "instruments",
      label: "Instruments",
      disabled: false,
    },
  ];

  useEffect(() => {
    setOriginArea(handleOriginAreas()[0]);
  }, [areas]);

  const handleOriginAreas = () => {
    const newAreaList = [];

    departments[0].allowed_areas?.forEach((areaId) => {
      if (areas.find((area) => area.id === areaId)) {
        newAreaList.push(areas.find((area) => area.id === areaId));
      }
    });

    return newAreaList;
  };

  const data = (tab === "kits" && cssdKits) || instruments || [];

  const radioButtons = [
    { id: "good", label: "Good" },
    { id: "damaged", label: "Damaged" },
    { id: "non_usable", label: "Non Usable" },
  ];

  const [nextProcess, setNextProcess] = useState({
    process: "",
    status: "",
    label: "",
  });
  const [isUpdateAssetCondition, toggleUpdateAssetCondition] = useState(false);

  useEffect(() => {
    if (row?.id) {
      if (
        row.updateCondition !== condition ||
        row.updateAvailability !== availability
      ) {
        toggleUpdateAssetCondition(true);
      } else {
        toggleUpdateAssetCondition(false);
      }
    }
  }, [row, availability, condition]);

  const checkBoxList = [
    {
      key: 3,
      process: "sterilize",
      name: "Sterilize",
      status: "awaiting_sterilize",
      id: "sterilize",
    },
    {
      key: 4,
      process: "dispatch",
      name: "Dispatch",
      status: "awaiting_dispatch",
      id: "dispatch",
    },
  ];

  useEffect(() => {
    let nextProcess = null;
    for (let i = 0; i < checkBoxList.length; i++) {
      if (departments[0]?.allowed_process?.includes(checkBoxList[i].process)) {
        nextProcess = checkBoxList[i];
        break;
      }
    }

    setNextProcess(nextProcess);
  }, [row]);

  const handlePack = (e) => {
    e.preventDefault();

    toggleComplete(true);
    const availableDevices = {};
    const devicesCondition = {};

    availableDevices[row.id] = availability;
    devicesCondition[row.id] = condition;

    const body = {
      entity_id: row.id,
      available_devices: availableDevices,
      devices_condition: devicesCondition,
      packed_department_id: departments[0]?.id,
      next_phase: nextProcess.process,
      kit_status: nextProcess.status,
      material_used: material?.id,
      wrap_required: (material?.id !== "Pouch" && false) || true,
      wrap_count: wrapCount,
      update_asset_condition: isUpdateAssetCondition,
      entity_type: "asset",
    };

    requestMethods
      .POST({
        endpoint: `/v4/cssd-kit/packing/${companyId}/${units[0].id}/create`,
        body,
      })
      .then((res) => {
        setTimeout(() => {
          togglePackAsset(false);
          toggleComplete(false);

          if (tab === "kits") {
            requestMethods
              .GET({
                endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
                params: {
                  custodianDepartmentId: departments[0].id,
                  page,
                  size: rowsPerPage,
                  status: "awaiting_pack",
                  location: originArea?.id,
                },
              })
              .then((res) => {
                setCssdKits(res.cssd_kits);
                setTotalCount(res.total_count);
              })
              .catch((err) => setErrorMessage(err.message));
          } else {
            requestMethods
              .GET({
                endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
                params: {
                  custodian_department_id: departments[0].id,
                  ignore_paging: false,
                  cssdStatus: "awaiting_pack",
                  is_kit_assigned: "unassigned",
                  home_location_id: originArea?.id,
                  page,
                  size: rowsPerPage,
                },
              })
              .then((res) => {
                setInstruments(res.assets);
                setTotalCount(res.total_count);
              })
              .catch((err) => setErrorMessage(err.message));
          }
        }, 1000);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        toggleComplete(false);
      });
  };

  const handleEmpty = () => {
    return (
      <div className={styles["pack__empty"]}>
        {"There are no records to display"}
      </div>
    );
  };

  return (
    <div className={styles["pack"]}>
      <PageHeader themeHeader title={"Pack"} returnHome={"/cssd_home"} />
      <NewModal isOpen={isPackAsset} onCancel={() => togglePackAsset(false)}>
        {(!complete && (
          <form onSubmit={handlePack}>
            <div className={styles["pack__preview"]}>
              <div>
                <div>{"Code"}</div>
                <div>{row?.code}</div>
              </div>
              <div>
                <div>{"Name"}</div>
                <div>{row?.name}</div>
              </div>
              <div>
                <div>{"Life Cycle"}</div>
                <div>{row?.life_cycle_count || "--"}</div>
              </div>
              <div>
                <div>{"Usage Cycle"}</div>
                <div>{row?.current_life_cycle_count + 1 || "1"}</div>
              </div>
            </div>
            {row?.lifeCycle &&
              row?.usageCycle &&
              row?.usageCycle + 1 > row?.lifeCycle && (
                <div className={styles["pack__red"]}>
                  {"Life Cycle count limit exceeds."}
                </div>
              )}
            <div className={styles["radio-container__rule"]}>
              <AdvancedDropdown
                value={material}
                options={packingMaterials?.map((material) => ({
                  id: material,
                  name: removeUnderScore(material),
                }))}
                onChange={(value) => setMaterial(value)}
                label={"Packing Material"}
                labelClass={styles["radio-container__dropdown"]}
                required
              />
              {material?.id !== "Pouch" && (
                <TextInput
                  type="number"
                  value={wrapCount}
                  onChange={(value) => {
                    setWrapCount(value);
                  }}
                  label={"Wrap Count"}
                  hideArrow
                  required
                  className={styles["radio-container__text"]}
                />
              )}
            </div>
            <div className={styles["pack__input-container"]}>
              <div className={styles["pack__checkbox"]}>
                <div>{"Availability:"}</div>
                {(row?.updateAvailability && (
                  <input
                    type={"checkbox"}
                    checked={availability}
                    onChange={() => {
                      setAvailability(!availability);
                    }}
                  />
                )) || <div>{"Not Available"}</div>}
              </div>
              {(row?.updateCondition === "good" && (
                <AdvancedDropdown
                  options={radioButtons}
                  value={{ id: condition, name: removeUnderScore(condition) }}
                  onChange={(value) => setCondition(value.id)}
                  label={"Condition"}
                  labelClass={styles["pack__dropdown"]}
                />
              )) || (
                <div className={styles["pack__condition"]}>
                  <div>{"Condition:"}</div>
                  <div>{removeUnderScore(row?.updateCondition || "")}</div>
                </div>
              )}
            </div>
            <div className={styles["radio-container"]}>
              <div className={styles["radio-container__process"]}>
                <label>{"Next Process :"}</label>
                <label>{nextProcess.name}</label>
              </div>
              <div className={styles["radio-container__check"]}>
                <u onClick={() => toggleNextStep(!isNextStep)}>{"Change ?"}</u>
              </div>
              {isNextStep && (
                <AdvancedDropdown
                  options={checkBoxList.filter((item) =>
                    departments[0]?.allowed_process?.includes(item.process)
                  )}
                  value={nextProcess}
                  onChange={(value) => setNextProcess(value)}
                />
              )}
            </div>
            <div className={styles["pack__button-container"]}>
              <Button
                onClick={() => togglePackAsset(false)}
                cancelButton
                className={styles["pack__button"]}
              >
                {"Cancel"}
              </Button>
              <Button
                className={styles["pack__button"]}
                disabled={complete}
                type="submit"
              >
                {"Complete"}
              </Button>
            </div>
          </form>
        )) || (
          <div className={styles["pack__success-message"]}>
            {"Packed Successfully"}
          </div>
        )}
      </NewModal>
      <div className={styles["pack__main-section"]}>
        <div className={styles["pack__filter"]}>
          <AdvancedDropdown
            label={"Department"}
            options={departments}
            value={departments[0]}
            disabled={true}
          />
          <AdvancedDropdown
            label={"Area"}
            value={originArea}
            options={handleOriginAreas()}
            onChange={(value) => setOriginArea(value)}
            disabled={handleOriginAreas().length === 1}
          />
        </div>
        <div className={styles["pack__tab-container"]}>
          {tabList.map((item) => (
            <div
              id={item.key}
              key={item.key}
              className={joinClass(
                styles["pack__tab-container__tab"],
                item.disabled && styles["pack__tab-container__disabled"],
                tab === item.key &&
                  !item.disabled &&
                  styles["pack__tab-container__active"]
              )}
              onClick={() => {
                if (!item.disabled) {
                  setTab(item.key);
                  setPage(1);
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        {(cssdKits.length > 0 && tab === "kits" && (
          <div className={styles["pack__table"]}>
            <div className={styles["pack__column-container"]}>
              {kitColumns.map((item) => (
                <div key={item}>{removeUnderScore(item)}</div>
              ))}
            </div>
            <div className={styles["pack__row-container"]}>
              {cssdKits.map((kit) => (
                <div
                  onClick={() =>
                    history.push(
                      `/cssd_home/Pack/Pack_kit/${units[0].id}/${kit.id}`
                    )
                  }
                  className={styles["pack__row"]}
                  key={kit.id}
                >
                  <div>{kit.code}</div>
                  <div>{kit.name}</div>
                </div>
              ))}
            </div>
          </div>
        )) ||
          (tab === "kits" && handleEmpty())}
        {(instruments.length > 0 && tab === "instruments" && (
          <div className={styles["pack__table"]}>
            <div className={styles["pack__column-container"]}>
              {kitColumns.map((item) => (
                <div key={item}>{removeUnderScore(item)}</div>
              ))}
            </div>
            <div className={styles["pack__row-container"]}>
              {instruments.map((instrument) => (
                <div
                  onClick={() => {
                    const availability =
                      (instrument.availability === "available" && true) ||
                      false;
                    const condition =
                      (instrument.condition === "active" && "good") ||
                      instrument.condition;
                    togglePackAsset(true);
                    setRow({
                      ...instrument,
                      updateAvailability: availability,
                      updateCondition: condition,
                    });
                    setAvailability(availability);
                    setCondition(condition);
                  }}
                  className={styles["pack__row"]}
                  key={instrument.id}
                >
                  <div>{instrument.code}</div>
                  <div>{instrument.name}</div>
                </div>
              ))}
            </div>
          </div>
        )) ||
          (tab === "instruments" && handleEmpty())}
        {data.length > 0 && (
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            setPage={setPage}
            listCount={
              (tab === "kits" && cssdKits.length) || instruments.length
            }
          />
        )}
      </div>
      <div className={styles["pack__message"]}>
        {errorMessage && removeUnderScore(errorMessage)}
      </div>
    </div>
  );
};

export default Pack;
