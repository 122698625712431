import React, { useEffect, useMemo, useState } from "react";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import PageHeader from "../../../components/PageHeader";
import requestMethods from "../../../helpers/request";
import {
  filteredDepartmentList,
  getItemFromLocalStorage,
  joinClass,
} from "../../../helpers/utils";
import { AiFillDelete } from "react-icons/ai";
import Button from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import Toaster from "../../../components/Toaster";
import TextInput from "../../../components/TextInput";
import styles from "./index.module.css";

const Dispatch = () => {
  const {
    units = [],
    company: { id: companyId } = {},
    departments = [],
  } = getItemFromLocalStorage("user") || {};

  const filteredCustodianDepartments = useMemo(() => {
    return filteredDepartmentList(departments, "custodian", true);
  }, []);

  const [loading, setLoading] = useState("none");
  const [kit, setKit] = useState(null);
  const [cssdKits, setCssdKits] = useState([]);
  const [custodianDepartments, setCustodianDepartments] = useState([]);
  const [custodianDepartment, setCustodianDepartment] = useState(null);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState(null);
  const [custodianDepartmentAreas, setCustodianDepartmentAreas] = useState([]);
  const [tab, setTab] = useState("kits");
  const [dispatchKits, setDispatchKits] = useState([]);
  const [isDispatch, toggleDispatch] = useState(false);
  const [cssdConsumableCategory, setCssdConsumableCategory] = useState(null);
  const [cssdConsumableCategories, setCssdConsumableCategories] = useState([]);
  const [cssdConsumable, setCssdConsumable] = useState(null);
  const [cssdConsumables, setCssdConsumables] = useState([]);
  const [dispatchCssdConsumables, setDispatchCssdConsumables] = useState([]);
  const [count, setCount] = useState(1);
  const [addConsumable, toggleAddConsumable] = useState(false);
  const [addKits, toggleAddKits] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [isCreating, toggleCreate] = useState(false);
  const [instruments, setInstruments] = useState([]);
  const [originArea, setOriginArea] = useState(null);
  const [dispatchInstruments, setDispatchInstruments] = useState([]);
  const [addInstrument, toggleAddInstrument] = useState(false);
  const [instrument, setInstrument] = useState(null);

  useEffect(() => {
    if (originArea?.id && tab === "kits") {
      setLoading("loading");

      requestMethods
        .GET({
          endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
          params: {
            custodianDepartmentId: filteredCustodianDepartments[0].id,
            ignore_paging: true,
            status: "awaiting_dispatch",
            location: originArea?.id,
          },
        })
        .then((res) => {
          setLoading("loaded");
          setCssdKits(res.cssd_kits);
        })
        .catch((err) => setMessage({ type: "error", text: err.message }));
    }
  }, [originArea, tab]);

  useEffect(() => {
    if (originArea?.id && tab === "instruments") {
      setLoading("loading");
      requestMethods
        .GET({
          endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
          params: {
            custodian_department_id: filteredCustodianDepartments[0].id,
            ignore_paging: true,
            cssdStatus: "awaiting_dispatch",
            is_kit_assigned: "unassigned",
            home_location_id: originArea?.id,
          },
        })
        .then((res) => {
          setLoading("loaded");
          setInstruments(res.assets);
        })
        .catch((err) => setMessage({ type: "error", text: err.message }));
    }
  }, [originArea, tab]);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/area/${companyId}/${units[0].id}/get-areas`,
        params: { ignore_paging: true },
      })
      .then((res) => {
        setAreas(res.areas);
      })
      .catch((err) => setMessage({ type: "error", text: err.message }));
    requestMethods
      .POST({
        endpoint: `v2/department/${companyId}/get-all`,
        params: {
          ignore_paging: true,
          include_all: true,
        },
      })
      .then((res) => {
        if (res.status) {
          setCustodianDepartments(
            filteredDepartmentList(res.departments, "custodian", true)
          );
        }
      })
      .catch((err) => setMessage({ type: "error", text: err.message }));
  }, []);

  useEffect(() => {
    const allowedAreas = custodianDepartment?.allowed_areas || [];

    if (allowedAreas.length > 0 && areas.length > 0) {
      const newFilteredAreaList = areas.filter((area) =>
        allowedAreas.includes(area.id)
      );
      setCustodianDepartmentAreas(newFilteredAreaList);

      if (newFilteredAreaList.length === 1) {
        setArea(newFilteredAreaList[0]);
      }
    } else {
      setCustodianDepartmentAreas([]);
      setArea(null);
    }
  }, [custodianDepartment, areas]);

  const tabList = [
    {
      key: "kits",
      label: "KITs",
    },
    {
      key: "consumables",
      label: "Consumables",
    },
    {
      key: "instruments",
      label: "Instruments",
    },
  ];

  const handleAdd = (value, setValue, setList, list) => {
    if (value) {
      setList([...list, value]);
      setValue(null);
    }
    setMessage({ type: "success", text: `${value.name} Added` });
  };

  const handleCssdKit = (list, dispatchList) => {
    if (dispatchList.length > 0) {
      const newList = [];
      list.forEach((item) => {
        if (!dispatchList.find((newItem) => newItem.id === item.id)) {
          newList.push(item);
        }
      });
      return newList;
    } else {
      return list;
    }
  };

  const handleDispatchButton = () => {
    if (
      dispatchKits.length > 0 ||
      dispatchCssdConsumables.length > 0 ||
      dispatchInstruments.length > 0
    ) {
      return (
        <Button
          type="submit"
          className={styles["dispatch__create-button"]}
          disabled={isCreating}
        >
          {"Dispatch"}
        </Button>
      );
    }
  };

  const handleDispatch = () => {
    toggleCreate(true);
    const cssdKitMeta = {};
    const cssdConsumableMeta = {};

    if (dispatchKits.length > 0) {
      dispatchKits.forEach((kit) => {
        cssdKitMeta[kit.id] = 1;
      });
    }

    if (dispatchCssdConsumables.length > 0) {
      dispatchCssdConsumables.forEach((consumable) => {
        cssdConsumableMeta[consumable.id] = consumable.qty;
      });
    }

    const body = {
      origin_department_id: filteredCustodianDepartments[0].id,
      ...((dispatchKits.length > 0 || dispatchInstruments.length > 0) && {
        origin_area_id: originArea.id,
      }),
      destination_department_id: custodianDepartment.id,
      destination_area_id: area.id,
      cssd_kits_meta: cssdKitMeta,
      cssd_consumables_meta: cssdConsumableMeta,
      next_phase: "receive",
      kit_status: "dispatched",
      associated_assets: dispatchInstruments.map((instrument) => instrument.id),
    };

    requestMethods
      .POST({
        endpoint: `v4/cssd/kit/transaction/${companyId}/${units[0].id}/create`,
        body,
      })
      .then((res) => {
        if (res.status) {
          toggleDispatch(false);
          setMessage({
            type: "success",
            text: "Transaction created successfully",
          });

          setDispatchKits([]);
          setDispatchCssdConsumables([]);
          setCssdConsumableCategory(null);
          setKit(null);
          setCssdConsumable(null);
          setLoading("loading");
          toggleCreate(false);
          setDispatchInstruments([]);
          setInstrument(null);

          requestMethods
            .GET({
              endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
              params: {
                custodianDepartmentId: filteredCustodianDepartments[0].id,
                ignore_paging: true,
                status: "awaiting_dispatch",
                location: originArea?.id,
              },
            })
            .then((res) => {
              setLoading("loaded");
              setCssdKits(res.cssd_kits);
            })
            .catch((err) => setMessage({ type: "error", text: err.message }));

          requestMethods
            .GET({
              endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
              params: {
                custodian_department_id: filteredCustodianDepartments[0].id,
                ignore_paging: true,
                cssdStatus: "awaiting_dispatch",
                is_kit_assigned: "unassigned",
                home_location_id: originArea?.id,
              },
            })
            .then((res) => {
              setLoading("loaded");
              setInstruments(res.assets);
            })
            .catch((err) => setMessage({ type: "error", text: err.message }));
        }
      })
      .catch((err) => {
        setMessage({ type: "error", text: err.message });
        toggleCreate(false);
      });
  };

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/cssd/consumable/category/${companyId}/get-all`,
        params: {
          ignore_paging: true,
        },
      })
      .then((res) => {
        if (res.status) {
          setCssdConsumableCategories(res.categories);
        }
      })
      .catch((err) => setMessage({ type: "error", text: err.message }));
  }, []);

  useEffect(() => {
    if (cssdConsumableCategory) {
      requestMethods
        .GET({
          endpoint: `v4/cssd/consumable/${companyId}/get-all`,
          params: {
            ignore_paging: true,
            cssdConsumableCategoryId: cssdConsumableCategory.id,
          },
        })
        .then((res) => {
          if (res.status) {
            setCssdConsumables(res.consumables);
          }
        })
        .catch((err) => setMessage({ type: "error", text: err.message }));
    }
  }, [cssdConsumableCategory]);

  useEffect(() => {
    setOriginArea(handleOriginAreas()[0]);
  }, [areas]);

  const handleOriginAreas = () => {
    const newAreaList = [];

    filteredCustodianDepartments[0].allowed_areas?.forEach((areaId) => {
      if (areas.find((area) => area.id === areaId)) {
        newAreaList.push(areas.find((area) => area.id === areaId));
      }
    });

    return newAreaList;
  };

  return (
    <div className={styles["dispatch"]}>
      {message.text && <Toaster message={message} setMessage={setMessage} />}
      <PageHeader themeHeader title={"Dispatch"} returnHome={"/cssd_home"} />
      <NewModal isOpen={isDispatch} onCancel={() => toggleDispatch(false)}>
        <div className={styles["dispatch__notes"]}>
          {"Confirm this transaction ?"}
        </div>
        <div className={styles["dispatch__button-container"]}>
          <Button
            cancelButton
            onClick={() => toggleDispatch(false)}
            className={styles["dispatch__button"]}
          >
            {"Back"}
          </Button>
          <Button
            onClick={handleDispatch}
            className={styles["dispatch__button"]}
            disabled={isCreating}
          >
            {"Confirm"}
          </Button>
        </div>
      </NewModal>
      <NewModal
        isOpen={addConsumable}
        onCancel={() => toggleAddConsumable(false)}
      >
        <div className={styles["dispatch__consumable-container"]}>
          <AdvancedDropdown
            label="Consumable Category"
            value={cssdConsumableCategory}
            onChange={(value) => setCssdConsumableCategory(value)}
            options={cssdConsumableCategories}
          />
          <AdvancedDropdown
            label="Consumable"
            value={cssdConsumable}
            onChange={(value) => setCssdConsumable(value)}
            disabled={!cssdConsumableCategory}
            options={handleCssdKit(cssdConsumables, dispatchCssdConsumables)}
          />
          <TextInput
            value={count}
            onChange={(value) => setCount(value)}
            label="Qty"
          />
          <div className={styles["dispatch__button-container"]}>
            <Button
              onClick={() => {
                toggleAddConsumable(false);
              }}
              type="button"
              cancelButton
              className={styles["dispatch__button"]}
            >
              {"Cancel"}
            </Button>
            <Button
              disabled={!cssdConsumable}
              onClick={() => {
                const newConsumable = { ...cssdConsumable, qty: count };
                handleAdd(
                  newConsumable,
                  setCssdConsumable,
                  setDispatchCssdConsumables,
                  dispatchCssdConsumables
                );
              }}
              type="button"
              className={styles["dispatch__button"]}
            >
              {"Add"}
            </Button>
          </div>
        </div>
      </NewModal>
      <NewModal isOpen={addKits} onCancel={() => toggleAddKits(false)}>
        <div className={styles["dispatch__kit-container"]}>
          <AdvancedDropdown
            value={kit}
            options={handleCssdKit(cssdKits, dispatchKits)}
            onChange={(value) => setKit(value)}
            label="KIT"
            isLoading={loading === "loading"}
          />
          <div className={styles["dispatch__button-container"]}>
            <Button
              onClick={() => {
                toggleAddKits(false);
              }}
              type="button"
              cancelButton
              className={styles["dispatch__button"]}
            >
              {"Cancel"}
            </Button>
            <Button
              disabled={!kit}
              onClick={() =>
                handleAdd(kit, setKit, setDispatchKits, dispatchKits)
              }
              type="button"
              className={styles["dispatch__button"]}
            >
              {"Add"}
            </Button>
          </div>
        </div>
      </NewModal>
      <NewModal
        isOpen={addInstrument}
        onCancel={() => toggleAddInstrument(false)}
      >
        <div className={styles["dispatch__kit-container"]}>
          <AdvancedDropdown
            value={instrument}
            options={handleCssdKit(instruments, dispatchInstruments)}
            onChange={(value) => setInstrument(value)}
            label="Instrument"
            isLoading={loading === "loading"}
          />
          <div className={styles["dispatch__button-container"]}>
            <Button
              onClick={() => {
                toggleAddInstrument(false);
              }}
              type="button"
              cancelButton
              className={styles["dispatch__button"]}
            >
              {"Cancel"}
            </Button>
            <Button
              disabled={!instrument}
              onClick={() =>
                handleAdd(
                  instrument,
                  setInstrument,
                  setDispatchInstruments,
                  dispatchInstruments
                )
              }
              type="button"
              className={styles["dispatch__button"]}
            >
              {"Add"}
            </Button>
          </div>
        </div>
      </NewModal>
      <form
        className={styles["dispatch__main-section"]}
        onSubmit={(e) => {
          e.preventDefault();
          toggleDispatch(true);
        }}
      >
        <div className={styles["dispatch__destination"]}>
          <div>{"Origin"}</div>
          <div className={styles["dispatch__box"]}>
            <AdvancedDropdown
              options={filteredCustodianDepartments}
              label={"Department"}
              value={filteredCustodianDepartments[0]}
              onChange={(value) => console.log(value)}
              disabled={filteredCustodianDepartments.length === 1}
              required
            />
            <AdvancedDropdown
              options={handleOriginAreas()}
              label={"Area"}
              onChange={(value) => setOriginArea(value)}
              value={originArea}
              disabled={handleOriginAreas().length === 1}
              required
            />
          </div>
        </div>
        <div className={styles["dispatch__destination"]}>
          <div>{"Destination"}</div>
          <div className={styles["dispatch__box"]}>
            <AdvancedDropdown
              options={custodianDepartments.filter(
                (dep) => dep.id !== filteredCustodianDepartments[0].id
              )}
              label={"Department"}
              value={custodianDepartment}
              onChange={(value) => setCustodianDepartment(value)}
              disabled={custodianDepartments.length === 1}
              required
            />
            <AdvancedDropdown
              options={custodianDepartmentAreas}
              label={"Area"}
              onChange={(value) => setArea(value)}
              value={area}
              disabled={custodianDepartmentAreas.length === 1}
              required
            />
          </div>
        </div>
        <div className={styles["dispatch__tab-container"]}>
          {tabList.map((item) => (
            <div
              id={item.key}
              key={item.key}
              className={joinClass(
                styles["dispatch__tab-container__tab"],
                tab === item.key && styles["dispatch__tab-container__active"]
              )}
              onClick={() => setTab(item.key)}
            >
              {item.label}
            </div>
          ))}
        </div>
        {tab === "kits" && (
          <>
            <div className={styles["dispatch__link"]}>
              <u onClick={() => toggleAddKits(true)}>{"Add KIT "}</u>
            </div>
            {dispatchKits.length > 0 && (
              <div className={styles["dispatch__table"]}>
                <div className={styles["dispatch__columns-container"]}>
                  <div>{"Code"}</div>
                  <div>{"Name"}</div>
                </div>
                <div className={styles["dispatch__row-container"]}>
                  {dispatchKits.map((kit) => (
                    <div key={kit.id} className={styles["dispatch__row"]}>
                      <div>{kit.code}</div>
                      <div>{kit.name}</div>
                      <div
                        className={styles["dispatch__table-button"]}
                        onClick={() => {
                          setDispatchKits(
                            dispatchKits.filter((item) => item.id !== kit.id)
                          );
                        }}
                      >
                        <AiFillDelete />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        {tab === "instruments" && (
          <>
            <div className={styles["dispatch__link"]}>
              <u onClick={() => toggleAddInstrument(true)}>
                {"Add Instrument "}
              </u>
            </div>
            {dispatchInstruments.length > 0 && (
              <div className={styles["dispatch__table"]}>
                <div className={styles["dispatch__columns-container"]}>
                  <div>{"Code"}</div>
                  <div>{"Name"}</div>
                </div>
                <div className={styles["dispatch__row-container"]}>
                  {dispatchInstruments.map((asset) => (
                    <div key={asset.id} className={styles["dispatch__row"]}>
                      <div>{asset.code}</div>
                      <div>{asset.name}</div>
                      <div
                        className={styles["dispatch__table-button"]}
                        onClick={() => {
                          setDispatchInstruments(
                            dispatchInstruments.filter(
                              (item) => item.id !== asset.id
                            )
                          );
                        }}
                      >
                        <AiFillDelete />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {tab === "consumables" && (
          <>
            <div className={styles["dispatch__link"]}>
              <u onClick={() => toggleAddConsumable(true)}>
                {"Add Consumable "}
              </u>
            </div>
            {dispatchCssdConsumables.length > 0 && (
              <div className={styles["dispatch__table"]}>
                <div
                  className={joinClass(
                    styles["dispatch__columns-container"],
                    styles["dispatch__consumable-header"]
                  )}
                >
                  <div>{"Code"}</div>
                  <div>{"Name"}</div>
                  <div>{"Qty"}</div>
                </div>
                <div
                  className={joinClass(
                    styles["dispatch__row-container"],
                    styles["dispatch__consumable-row"]
                  )}
                >
                  {dispatchCssdConsumables.map((consumable) => (
                    <div
                      key={consumable.id}
                      className={styles["dispatch__row"]}
                    >
                      <div>{consumable.code}</div>
                      <div>{consumable.name}</div>
                      <div>{consumable.qty}</div>
                      <div
                        className={styles["dispatch__table-button"]}
                        onClick={() => {
                          setDispatchCssdConsumables(
                            dispatchCssdConsumables.filter(
                              (item) => item.id !== consumable.id
                            )
                          );
                        }}
                      >
                        <AiFillDelete />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {handleDispatchButton()}
      </form>
    </div>
  );
};

export default Dispatch;
