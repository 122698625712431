import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../../../components/PageHeader";
import RadioButton from "../../../../components/RadioButton";
import TextInput from "../../../../components/TextInput";
import requestMethods from "../../../../helpers/request";
import {
  getItemFromLocalStorage,
  handleSpeak,
  removeUnderScore,
} from "../../../../helpers/utils";
import { RiBarcodeLine } from "react-icons/ri";
import Button from "../../../../components/Button";
import { history as redirect } from "../../../../helpers/utils";
import Toaster from "../../../../components/Toaster";
import styles from "./index.module.css";

const SurgeryExit = (history) => {
  const { unitId, surgeryId } = history.match.params;

  const { company: { id: companyId } = {} } =
    getItemFromLocalStorage("user") || {};

  const [surgeryDetails, setSurgeryDetails] = useState({});
  const [message, setMessage] = useState({ type: "", text: "" });
  const [spongeId, setSpongeId] = useState("");
  const [spongeStatus, setSpongeStatus] = useState("used");
  const [scannedList, setScannedList] = useState([]);
  const [unknownSponges, setUnknownSponges] = useState([]);
  const [isDisabledSpongeId, toggleDisabledSpongeId] = useState(false);
  const [isCreate, toggleCreate] = useState(false);

  const timerRef = useRef(null);
  const synthRef = useRef(null);
  const countRef = useRef(null);
  const spongeRef = useRef(null);

  useEffect(() => {
    return synthRef.current && synthRef.current.cancel();
  }, []);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/surgery/${companyId}/${unitId}/details/${surgeryId}`,
      })
      .then((res) => {
        if (res.status) {
          setSurgeryDetails(res);
        }
      })
      .catch((error) => setMessage({ type: "error", text: error.message }));
  }, []);

  const { code = "", associated_sponge_packets: packList = [] } =
    surgeryDetails;

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (spongeId) {
      timerRef.current = setTimeout(() => {
        const pack = handleSearchPackId(spongeId);
        const spongePack = handleSearchSpongeId(spongeId);
        const message = { type: "error", text: "" };

        if (pack) {
        } else {
          if (spongePack) {
            toggleDisabledSpongeId(true);
            if (scannedList.find((pack) => spongePack.code === pack.code)) {
              const pack = scannedList.find((pack) =>
                pack.scanned_sponges.find((sponge) => sponge.id === spongeId)
              );

              if (pack) {
                message.text = "Sponge already exist";
              } else {
                setScannedList(
                  scannedList.map((item) => {
                    if (item.code === spongePack.code) {
                      item.scanned_sponges.push({
                        id: spongeId,
                        status: spongeStatus,
                      });
                    }
                    return item;
                  })
                );
                countRef.current = countRef.current + 1;
                message.type = "success";
                message.text = `${countRef.current} counted ${
                  (handlePendingSponges() === 0 && "no") ||
                  handlePendingSponges()
                } pending`;
              }
            } else {
              countRef.current = countRef.current + 1;
              message.type = "success";
              message.text = `${countRef.current} counted ${
                (handlePendingSponges() === 0 && "no") || handlePendingSponges()
              } pending`;

              setScannedList([
                ...scannedList,
                {
                  ...spongePack,
                  scanned_sponges: [{ id: spongeId, status: spongeStatus }],
                },
              ]);
            }
          } else {
            setUnknownSponges([...unknownSponges, spongeId]);
            message.text = "Unknown sponge added";
          }
        }
        setMessage(message);
        handleSpeak(message.text, synthRef, () => {
          if (spongeRef.current) {
            toggleDisabledSpongeId(false);
            setSpongeId("");
            spongeRef.current.focus();
          }
        });
      }, 500);
    }
  }, [spongeId]);

  const handleSearchPackId = (id) => {
    const pack = packList.find((pack) => pack.code === id);
    return pack;
  };

  const handleSearchSpongeId = (id) => {
    const spongePack = packList.find((pack) => pack.sponge_codes.includes(id));
    return spongePack;
  };

  const handleTotlaSponges = () => {
    let length = 0;

    packList.forEach((pack) => {
      length = length + pack.sponge_codes.length;
    });

    return length;
  };

  const handleSpongesCount = (status) => {
    let length = 0;

    scannedList.forEach((pack) => {
      const spongeLength = pack.scanned_sponges.filter(
        (sponge) => sponge.status === status
      ).length;

      length = length + spongeLength;
    });

    return length;
  };

  const handlePendingSponges = () => {
    let newLength = 0;

    packList.forEach((pack) => {
      const separate = pack.sponge_codes.length;
      newLength = newLength + separate;
    });

    if (scannedList.length > 0) {
      const list = [];

      packList.forEach((pack) => {
        pack.sponge_codes.forEach((sponge) => {
          list.push(sponge);
        });
      });

      list.forEach((item) => {
        const pack = scannedList.find((pack) =>
          pack.scanned_sponges.find((sponge) => sponge.id === item)
        );
        if (pack) {
          newLength = newLength - 1;
        }
      });

      return newLength;
    } else {
      return newLength;
    }
  };

  const statusBar = [
    { key: 1, label: "Total Packs", value: packList.length },
    { key: 2, label: "Total Sponges", value: handleTotlaSponges() },
    {
      key: 3,
      label: "Used",
      value: handleSpongesCount("used"),
    },
    {
      key: 4,
      label: "Unused",
      value: handleSpongesCount("unused"),
    },
    { key: 5, label: "Pending", value: handlePendingSponges() },
  ];

  const handleUpdate = () => {
    toggleCreate(true);
    if (scannedList.length > 0) {
      if (synthRef.current) {
        synthRef.current.cancel();
      }
      const spongeStatus = {};
      const anomalies = [];
      const packetStatus = {};

      scannedList.forEach((pack) => {
        pack.scanned_sponges.forEach((sponge) => {
          spongeStatus[sponge.id] = sponge.status;
        });
      });

      packList.forEach((pack) => {
        pack.sponge_codes.forEach((code) => {
          if (
            !scannedList.find((s) =>
              s.scanned_sponges.find((item) => item.id === code)
            )
          ) {
            anomalies.push(code);
          }
        });
      });

      const body = {
        code: code,
        sponge_status: spongeStatus,
        packet_status: packetStatus,
        unknown_sponges: unknownSponges,
        anomalies: anomalies,
      };

      requestMethods
        .PUT({
          endpoint: `v4/surgery/${companyId}/${unitId}/update/${surgeryId}`,
          body,
        })
        .then((res) => {
          if (res.status) {
            setMessage({ type: "success", text: "Surgery exit successfully" });

            setTimeout(() => {
              redirect.push("/surgical_sponge_track_home/ot");
            }, 1000);
          }
        })
        .catch((error) => setMessage({ type: "error", text: error.message }));
    } else {
      if (spongeRef.current) spongeRef.current.focus();
      setMessage({ type: "error", text: "Add atleast one sponge to exit" });
      toggleCreate(false);
    }
  };

  return (
    <div className={styles["surgery-exit"]}>
      <PageHeader
        themeHeader
        title="OT Exit"
        returnHome="/surgical_sponge_track_home/ot"
      />
      {message.text && <Toaster message={message} setMessage={setMessage} />}
      <div className={styles["surgery-exit__main-section"]}>
        <div className={styles["surgery-exit__detail"]}>
          <div>
            <div>{"Surgery ID :"}</div>
            <div> {code}</div>
          </div>
        </div>
        <div className={styles["surgery-exit__preview"]}>
          {statusBar.map((item) => (
            <div key={item.key}>
              <div>{item.label}</div>
              <div>{item.value}</div>
            </div>
          ))}
        </div>
        <div className={styles["surgery-exit__input-container"]}>
          <TextInput
            value={spongeId}
            onChange={(value) => setSpongeId(value)}
            label="Sponge ID"
            autoFocus
            className={styles["surgery-exit__text"]}
            inputRef={spongeRef}
            disabled={isDisabledSpongeId}
          />
          <div className={styles["surgery-exit__barcode"]}>
            <RiBarcodeLine
              title="Scan barcode"
              onClick={() => spongeRef.current.focus()}
            />
          </div>
          <div className={styles["surgery-exit__radio-container"]}>
            <RadioButton
              label="Used"
              name="used"
              value={spongeStatus}
              onChange={() => {
                setSpongeStatus("used");
                spongeRef.current.focus();
              }}
            />
            <RadioButton
              label="Unused"
              name="unused"
              value={spongeStatus}
              onChange={() => {
                setSpongeStatus("unused");
                spongeRef.current.focus();
              }}
            />
          </div>
        </div>

        <div className={styles["surgery-exit__title"]}>
          <div>{"Sponges"}</div>
        </div>
        <div className={styles["surgery-exit__table"]}>
          <div className={styles["surgery-exit__columns-container"]}>
            <div>{"Sl No"}</div>
            <div>{"Pack ID"}</div>
            <div>{"# of sponges"}</div>
            <div>{"Sponge IDs"}</div>
            <div>{"Status"}</div>
          </div>
          {(scannedList.length > 0 && (
            <div className={styles["surgery-exit__row-container"]}>
              {scannedList.map((row, index) => (
                <div key={row.id}>
                  <div>{index + 1}</div>
                  <div>{row.code}</div>
                  <div>{row.scanned_sponges.length}</div>
                  <div className={styles["surgery-exit__code"]}>
                    {row.scanned_sponges.map((sponge) => (
                      <div key={sponge.id}>{sponge.id}</div>
                    ))}
                  </div>
                  <div className={styles["surgery-exit__code"]}>
                    {row.scanned_sponges.map((sponge) => (
                      <div
                        key={sponge.id}
                        className={styles[`surgery-exit__${sponge.status}`]}
                      >
                        {removeUnderScore(sponge.status)}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )) || (
            <div className={styles["surgery-exit__no-record"]}>
              {"No records"}
            </div>
          )}
        </div>

        <div className={styles["surgery-exit__button-container"]}>
          <Button
            type="button"
            cancelButton
            onClick={() => redirect.push("/surgical_sponge_track_home/ot")}
            className={styles["surgery-exit__button"]}
          >
            {"Back"}
          </Button>
          <Button
            type="button"
            onClick={handleUpdate}
            className={styles["surgery-exit__button"]}
            disabled={isCreate}
          >
            {"Mark Exit"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SurgeryExit;
