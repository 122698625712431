import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import PageHeader from "../../../components/PageHeader";
import requestMethods from "../../../helpers/request";
import {
  filteredDepartmentList,
  getItemFromLocalStorage,
  history,
  removeUnderScore,
} from "../../../helpers/utils";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/Pagination";
import styles from "./index.module.css";

const Inward = () => {
  const {
    company: { id: companyId } = {},
    units = [],
    departments = [],
  } = getItemFromLocalStorage("user") || {};

  const filteredCustodianDepartments = useMemo(() => {
    return filteredDepartmentList(departments, "custodian", true);
  }, []);

  const [transactions, setTransactions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(7);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState("none");

  useEffect(() => {
    setLoading("loading");
    requestMethods
      .GET({
        endpoint: `v4/cssd/kit/transaction/${companyId}/${units[0].id}/get-all`,
        params: {
          status: "dispatched",
          destinationDepartmentId: filteredCustodianDepartments[0]?.id,
          page,
          size: rowsPerPage,
        },
      })
      .then((res) => {
        setTransactions(res.cssd_kit_transactions);
        setTotalCount(res.total_count);
        setLoading("loaded");
      })
      .catch((err) => {
        setErrorMessage(err?.message);
      });
  }, [filteredCustodianDepartments, rowsPerPage, page]);

  const transactionColumns = ["id", "from", "dispatchedBy"];

  return (
    <div className={styles["inward"]}>
      <PageHeader themeHeader title={"Inward"} returnHome="/cssd_home" />
      {(transactions.length > 0 && loading === "loaded" && (
        <div className={styles["inward__main-section"]}>
          <div className={styles["inward__table"]}>
            <div className={styles["inward__column-container"]}>
              {transactionColumns.map((item) => (
                <div key={item}>{removeUnderScore(item)}</div>
              ))}
            </div>
            <div className={styles["inward__row-container"]}>
              {transactions.map((transaction) => (
                <div
                  onClick={() =>
                    history.push(
                      `/cssd_home/inward/receive/${units[0].id}/${transaction.id}`
                    )
                  }
                  className={styles["inward__row"]}
                  key={transaction.id}
                >
                  <div>{transaction.transaction_number}</div>
                  <div>{transaction.origin_department.name}</div>
                  <div>
                    <div>{transaction.dispatched_by.name}</div>
                    <div className={styles["inward__date"]}>
                      {moment(transaction.dispatched_on).format(
                        "Do MM YYYY hh:mm A"
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            setPage={setPage}
            listCount={transactions.length}
          />
        </div>
      )) ||
        (loading === "loading" && <Loader />) || (
          <div className={styles["inward__message"]}>
            {(errorMessage && removeUnderScore(errorMessage)) ||
              "No data available"}
          </div>
        )}
    </div>
  );
};

export default Inward;
