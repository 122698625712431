import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import Pagination from "../../../components/Pagination";
import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  history,
  joinClass,
  removeUnderScore,
} from "../../../helpers/utils";
import { GoPlus } from "react-icons/go";
import moment from "moment";
import Loader from "../../../components/Loader";
import { AiFillEdit } from "react-icons/ai";
import { BiExit } from "react-icons/bi";
import styles from "./index.module.css";

const Surgery = () => {
  const {
    units = [],
    company: { id: companyId } = {},
    privileges = [],
  } = getItemFromLocalStorage("user") || {};

  const [tab, setTab] = useState("started");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [surgeryList, setSurgeryList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState("none");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setLoading("loading");
    requestMethods
      .GET({
        endpoint: `v4/surgery/${companyId}/${units[0].id}/get-all`,
        params: {
          status: tab,
          sort: -1,
          page: page,
          size: rowsPerPage,
        },
      })
      .then((res) => {
        if (res.status) {
          setSurgeryList(res.surgery_responses);
          setTotalCount(res.total_count);
        }
        setLoading("loaded");
      })
      .catch((error) => {
        setLoading("notLoaded");
        setMessage(error.message);
      });
  }, [tab, rowsPerPage, page]);

  const tabList = [
    {
      key: "started",
      label: "Started",
      disabled: false,
    },
    {
      key: "completed",
      label: "Completed",
      disabled: false,
    },
  ];

  return (
    <div className={styles["surgery"]}>
      <PageHeader
        themeHeader
        title="OT"
        returnHome="/surgical_sponge_track_home"
      />
      <div className={styles["surgery__main-section"]}>
        {privileges["surgeryManage"] && (
          <div className={styles["surgery__create"]}>
            <div
              onClick={() =>
                history.push("/surgical_sponge_track_home/ot/entry")
              }
            >
              <GoPlus />
              <label>{"Mark Entry"}</label>
            </div>
          </div>
        )}
        <div className={styles["surgery__tab-container"]}>
          {tabList.map((item) => (
            <div
              id={item.key}
              key={item.key}
              className={joinClass(
                styles["surgery__tab-container__tab"],
                item.disabled && styles["surgery__tab-container__disabled"],
                tab === item.key && styles["surgery__tab-container__active"]
              )}
              onClick={() => {
                if (!item.disabled) {
                  setTab(item.key);
                  setPage(1);
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        {(totalCount > 0 && isLoading === "loaded" && (
          <div className={styles["surgery__sub-section"]}>
            <div className={styles["surgery__table"]}>
              <div
                className={joinClass(
                  styles["surgery__columns-container"],
                  tab === "started" && styles["surgery__five-columns"]
                )}
              >
                <div>{"Surgery ID"}</div>
                <div>{"Created By"}</div>
                <div>
                  {(tab === "started" && "Updated By") || "Completed By"}
                </div>
              </div>
              <div
                className={joinClass(
                  styles["surgery__row-container"],
                  tab === "started" && styles["surgery__five-rows"]
                )}
              >
                {surgeryList.map((surgery) => (
                  <div key={surgery.id}>
                    <div>{surgery.code}</div>
                    <div>
                      <div>{surgery.created_by.name}</div>
                      <div className={styles["surgery__date"]}>
                        {moment(surgery.created_on).format(
                          "Do MM YYYY hh:mm A"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>{surgery.updated_by.name}</div>
                      <div className={styles["surgery__date"]}>
                        {moment(surgery.updated_on).format(
                          "Do MM YYYY hh:mm A"
                        )}
                      </div>
                    </div>
                    {tab === "started" && (
                      <div className={styles["surgery__icon-container"]}>
                        <div
                          className={styles["surgery__icon"]}
                          onClick={() =>
                            history.push(
                              `/surgical_sponge_track_home/ot/entry/${units[0].id}/${surgery.id}`
                            )
                          }
                        >
                          <AiFillEdit />
                        </div>
                        <div
                          className={styles["surgery__icon"]}
                          onClick={() =>
                            history.push(
                              `/surgical_sponge_track_home/ot/exit/${units[0].id}/${surgery.id}`
                            )
                          }
                        >
                          <BiExit />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalCount > 0 && (
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                setPage={setPage}
                listCount={surgeryList.length}
              />
            )}
          </div>
        )) ||
          (isLoading === "loading" && <Loader />) || (
            <div className={styles["surgery__error-message"]}>
              {(message && removeUnderScore(message)) || "No data available"}
            </div>
          )}
      </div>
    </div>
  );
};

export default Surgery;
