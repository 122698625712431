import React, { useEffect, useState } from "react";
import Login from "./Pages/login";
import Asset from "./Pages/Asset";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "./helpers/utils";
import "./App.css";
import Home from "./Pages/home";
import { getItemFromLocalStorage } from "./helpers/utils";
import { ProtectedRoute } from "./components/ProtectedRoute";
import History from "./Pages/History";
import ServiceRequest from "./Pages/serviceRequest";
import AssetReturnRequest from "./Pages/returnRequest";
import AssetConditionUpdate from "./Pages/updateCondition";
import AssetLocationUpdate from "./Pages/updateLocation";
import AssetCustodianUpdate from "./Pages/updateCustodian";
import GatePass from "./Pages/gatePass";
import ModuleHome from "./components/ModuleHome";
import Condemnation from "./Pages/Condemnation";
import CssdHome from "./Pages/Cssd";
import Inward from "./Pages/Cssd/Inward";
import ReceiveKits from "./Pages/Cssd/Inward/ReceiveKit";
import Inspect from "./Pages/Cssd/Inspect";
import InspectKit from "./Pages/Cssd/Inspect/InspectKit";
import Pack from "./Pages/Cssd/Pack";
import PackKit from "./Pages/Cssd/Pack/PackKit";
import Wash from "./Pages/Cssd/Wash";
import Sterilize from "./Pages/Cssd/Sterilize";
import Dispatch from "./Pages/Cssd/Dispatch";
import SurgicalSpongeTrackHome from "./Pages/SST";
import Surgery from "./Pages/SST/Surgery";
import SurgeryCreate from "./Pages/SST/Surgery/SurgeryCreate";
import SurgeryExit from "./Pages/SST/Surgery/SurgeryExit";
import SurgicalSpongePacking from "./Pages/SST/SurgicalSpongePacking";
import SurgicalSpongePackingCreate from "./Pages/SST/SurgicalSpongePacking/SurgicalSpongePackingCreate";
import GatePassImageUpload from "./Pages/GatePassImageUpload";

export const appContext = React.createContext();

const App = () => {
  const userInfo = getItemFromLocalStorage("user") || {};
  const theme = !!getItemFromLocalStorage("theme");
  const [userData, setUserData] = useState(userInfo);
  const [selectedTheme, setTheme] = useState(theme);

  const toggleTheme = (value) => {
    localStorage.setItem("theme", value);
    setTheme(value);
  };

  useEffect(() => {
    if (selectedTheme) {
      document.documentElement.style.setProperty("--theme-color1", "#6e5fde");
      document.documentElement.style.setProperty("--theme-color2", "#4c48b3");
    } else {
      document.documentElement.style.setProperty("--theme-color1", "#9f488c");
      document.documentElement.style.setProperty("--theme-color2", "#e44d38");
    }
  }, [selectedTheme]);

  return (
    <appContext.Provider
      value={{
        userData,
        setUserData,
        theme: selectedTheme,
        setTheme: toggleTheme,
      }}
    >
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <ProtectedRoute exact path="/home" component={ModuleHome} />
          <ProtectedRoute exact path="/eam_home" component={Home} />
          <ProtectedRoute exact path="/asset/:id" component={Asset} />
          <ProtectedRoute exact path="/history" component={History} />
          <ProtectedRoute
            exact
            path="/serviceRequest/:id"
            component={ServiceRequest}
          />
          <ProtectedRoute
            exact
            path="/asset_condition_update/:id"
            component={AssetConditionUpdate}
          />
          <ProtectedRoute
            exact
            path="/asset_return_request/:id"
            component={AssetReturnRequest}
          />
          <ProtectedRoute
            exact
            path="/asset_location_update/:id"
            component={AssetLocationUpdate}
          />
          <ProtectedRoute
            exact
            path="/asset_custodian_update/:id"
            component={AssetCustodianUpdate}
          />
          <ProtectedRoute exact path="/gatePass/:id" component={GatePass} />
          <ProtectedRoute
            exact
            path="/condemnation/:id"
            component={Condemnation}
          />
          <ProtectedRoute exact path="/cssd_home" component={CssdHome} />
          <ProtectedRoute exact path="/cssd_home/inward" component={Inward} />
          <ProtectedRoute
            exact
            path="/cssd_home/inward/receive/:unitId/:transactionId"
            component={ReceiveKits}
          />
          <ProtectedRoute exact path="/cssd_home/inspect" component={Inspect} />
          <ProtectedRoute
            exact
            path="/cssd_home/inspect/inspect_kit/:unitId/:kitId"
            component={InspectKit}
          />
          <ProtectedRoute exact path="/cssd_home/pack" component={Pack} />
          <ProtectedRoute
            exact
            path="/cssd_home/pack/pack_kit/:unitId/:kitId"
            component={PackKit}
          />
          <ProtectedRoute exact path="/cssd_home/wash" component={Wash} />
          <ProtectedRoute
            exact
            path="/cssd_home/sterilize"
            component={Sterilize}
          />
          <ProtectedRoute
            exact
            path="/cssd_home/dispatch"
            component={Dispatch}
          />
          ,
          <ProtectedRoute
            exact
            path="/surgical_sponge_track_home"
            component={SurgicalSpongeTrackHome}
          />
          <ProtectedRoute
            exact
            path="/surgical_sponge_track_home/ot"
            component={Surgery}
          />
          <ProtectedRoute
            exact
            path="/surgical_sponge_track_home/ot/entry"
            component={SurgeryCreate}
          />
          <ProtectedRoute
            exact
            path="/surgical_sponge_track_home/ot/exit/:unitId/:surgeryId"
            component={SurgeryExit}
          />
          <ProtectedRoute
            exact
            path="/surgical_sponge_track_home/ot/entry/:unitId/:surgeryId"
            component={SurgeryCreate}
          />
          <ProtectedRoute
            exact
            path="/surgical_sponge_track_home/packing"
            component={SurgicalSpongePacking}
          />
          <ProtectedRoute
            exact
            path="/surgical_sponge_track_home/packing/create"
            component={SurgicalSpongePackingCreate}
          />
          <ProtectedRoute
            exact
            path="/gatePassImageUpload/:gatePassId"
            component={GatePassImageUpload}
          />
        </Switch>
      </Router>
    </appContext.Provider>
  );
};

export default App;
