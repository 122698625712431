import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import requestMethods from "../../../helpers/request";
import {
  filteredDepartmentList,
  getItemFromLocalStorage,
  joinClass,
  removeUnderScore,
} from "../../../helpers/utils";
import NewModal from "../../../components/NewModal";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import Pagination from "../../../components/Pagination";
import moment from "moment";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import Loader from "../../../components/Loader";
import { CgBrush } from "react-icons/cg";
import { GrCompliance } from "react-icons/gr";
import styles from "./index.module.css";
import { DatePicker } from "../../../components/DatePicker";

const Sterilize = () => {
  const {
    company: { id: companyId } = {},
    units = [],
    departments = [],
    id: userId,
  } = getItemFromLocalStorage("user") || {};

  const filteredCustodianDepartments = useMemo(() => {
    return filteredDepartmentList(departments, "custodian", true);
  }, []);

  const [tab, setTab] = useState("pending");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [cssdKits, setCssdKits] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isStart, toggleStart] = useState(false);
  const [row, setRow] = useState({});
  const [lineNumber, setLineNumber] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [sterilizeList, setSterilizeList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState("none");
  const [sterilizationProcess, setSterilizationProcess] = useState({});
  const [sterilizationProcessList, setSterilizationProcessList] = useState([]);
  const [expiryDate, setExpiryDate] = useState(moment());
  const [entityType, setEntityType] = useState({
    id: "cssd_kit",
    label: "KITs",
  });
  const [instruments, setInstruments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [originArea, setOriginArea] = useState(null);
  const [startOrComplete, toggleStartOrComplete] = useState(false);

  useEffect(() => {
    setLoading("loading");

    if (tab === "pending" && originArea?.id) {
      if (entityType.id === "cssd_kit") {
        requestMethods
          .GET({
            endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
            params: {
              custodianDepartmentId: filteredCustodianDepartments[0].id,
              page,
              size: rowsPerPage,
              status: "awaiting_sterilize",
              location: originArea?.id,
            },
          })
          .then((res) => {
            if (res.status) {
              setLoading("loaded");
              setTotalCount(res.total_count);
              setCssdKits(res.cssd_kits);
            }
          })
          .catch((err) => setErrorMessage(err.message));
      } else {
        requestMethods
          .GET({
            endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
            params: {
              custodian_department_id: departments[0].id,
              ignore_paging: false,
              cssdStatus: "awaiting_sterilize",
              is_kit_assigned: "unassigned",
              home_location_id: originArea?.id,
              page,
              size: rowsPerPage,
            },
          })
          .then((res) => {
            setInstruments(res.assets);
            setLoading("loaded");
            setTotalCount(res.total_count);
          })
          .catch((err) => setErrorMessage(err.message));
      }
    } else if (tab === "in_process" || tab === "completed") {
      requestMethods
        .GET({
          endpoint: `v4/cssd/kit/sterilize/${companyId}/${units[0].id}/get-all`,
          params: {
            page,
            size: rowsPerPage,
            departmentId: filteredCustodianDepartments[0].id,
            status: (tab === "in_process" && "in_progress") || "completed",
            sort: (tab === "in_process" && 1) || -1,
            entityType: entityType?.id,
          },
        })
        .then((res) => {
          if (res.status) {
            setLoading("loaded");
            setSterilizeList(res.cssd_kit_sterilize_list);
            setTotalCount(res.total_count);
          }
        })
        .catch((error) => setErrorMessage(error.message));
    }
  }, [
    page,
    rowsPerPage,
    filteredCustodianDepartments,
    tab,
    entityType,
    originArea,
  ]);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/area/${companyId}/${units[0].id}/get-areas`,
        params: { ignore_paging: true },
      })
      .then((res) => {
        setAreas(res.areas);
      })
      .catch((err) => setErrorMessage(err.message));
  }, []);

  useEffect(() => {
    requestMethods
      .GET({
        endpoint: `v4/cssd/sterilization_rule/${companyId}/${units[0].id}/get-all-cssd-kit-sterilization-types`,
      })
      .then((res) => {
        if (res.status) {
          setSterilizationProcessList(res.sterilization_types);
        }
      })
      .catch((error) => setErrorMessage(error.message));
  }, []);

  useEffect(() => {
    setOriginArea(handleOriginAreas()[0]);
  }, [areas]);

  const handleOriginAreas = () => {
    const newAreaList = [];

    departments[0].allowed_areas?.forEach((areaId) => {
      if (areas.find((area) => area.id === areaId)) {
        newAreaList.push(areas.find((area) => area.id === areaId));
      }
    });

    return newAreaList;
  };

  const tabList = [
    {
      key: "pending",
      label: "Pending",
      disabled: false,
    },
    {
      key: "in_process",
      label: "In Process",
      disabled: false,
    },
    {
      key: "completed",
      label: "Completed",
      disabled: false,
    },
  ];

  const handleStart = (e) => {
    e.preventDefault();
    toggleStartOrComplete(true);

    requestMethods
      .POST({
        endpoint: `v4/cssd/kit/sterilize/${companyId}/${units[0].id}/create`,

        body: {
          entity_id: row.id,
          started_by: userId,
          started_on: +moment().format("x"),
          line_number: lineNumber,
          sterilized_department_id: departments[0].id,
          sterilization_process: sterilizationProcess.id,
          entity_type: entityType?.id,
        },
      })
      .then((res) => {
        if (res.status) {
          toggleStartOrComplete(false);
          setSuccessMessage("Sterilize Started");
          setSterilizationProcess({});
          setLineNumber("");

          setTimeout(() => {
            if (entityType.id === "cssd_kit") {
              requestMethods
                .PUT({
                  endpoint: `v4/cssd/kit/${companyId}/${units[0].id}/update_status`,
                  body: {
                    cssd_kit_ids: [row.id],
                    next_phase: "sterilize",
                    kit_status: "under_sterilize",
                  },
                })
                .then((res) => {
                  if (res.status) {
                    toggleStart(false);
                    setLoading("loading");
                    setSuccessMessage("");

                    requestMethods
                      .GET({
                        endpoint: `/v4/cssd/kit/${companyId}/${units[0].id}/get-all`,
                        params: {
                          custodianDepartmentId:
                            filteredCustodianDepartments[0].id,
                          page,
                          size: rowsPerPage,
                          status: "awaiting_sterilize",
                        },
                      })
                      .then((res) => {
                        setLoading("loaded");
                        setTotalCount(res.total_count);
                        setCssdKits(res.cssd_kits);
                      })
                      .catch((err) => {
                        setErrorMessage(err.message);
                      });
                  }
                })
                .catch((error) => {
                  setErrorMessage(error.message);
                });
            } else {
              requestMethods
                .PUT({
                  endpoint: `/v3/asset/${companyId}/update_cssd_status/${row.id}`,
                  body: {
                    cssd_status: "under_sterilize",
                    unit_id: units[0].id,
                  },
                })
                .then((res) => {
                  if (res.status) {
                    toggleStart(false);
                    setLoading("loading");
                    setSuccessMessage("");
                    requestMethods
                      .GET({
                        endpoint: `v3/asset/${companyId}/${units[0].id}/management/get-all-assets`,
                        params: {
                          custodian_department_id: departments[0].id,
                          ignore_paging: false,
                          cssdStatus: "awaiting_sterilize",
                          is_kit_assigned: "unassigned",
                          home_location_id: originArea?.id,
                          page,
                          size: rowsPerPage,
                        },
                      })
                      .then((res) => {
                        setInstruments(res.assets);
                        setLoading("loaded");
                        setTotalCount(res.total_count);
                      })
                      .catch((err) => {
                        setErrorMessage(err.message);
                      });
                  }
                });
            }
          }, 1000);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        toggleStartOrComplete(false);
      });
  };

  const handleComplete = (e) => {
    e.preventDefault();
    toggleStartOrComplete(true);

    requestMethods
      .PUT({
        endpoint: `v4/cssd/kit/sterilize/${companyId}/${units[0].id}/update/${row.sterilizeId}`,
        body: {
          completed_by: userId,
          completed_on: +moment().format("x"),
          kit_status: "awaiting_dispatch",
          next_phase: "dispatch",
          expiry_date: +expiryDate.format("x"),
        },
      })
      .then((res) => {
        if (res.status) {
          toggleStartOrComplete(false);
          setSuccessMessage("Sterilize Completed");
          setTimeout(() => {
            toggleStart(false);
            setLoading("loading");
            setSuccessMessage("");
            requestMethods
              .GET({
                endpoint: `v4/cssd/kit/sterilize/${companyId}/${units[0].id}/get-all`,
                params: {
                  page,
                  size: rowsPerPage,
                  departmentId: filteredCustodianDepartments[0].id,
                  status: "in_progress",
                  sort: 1,
                  entityType: entityType?.id,
                },
              })
              .then((res) => {
                if (res.status) {
                  setLoading("loaded");
                  setSterilizeList(res.cssd_kit_sterilize_list);
                  setTotalCount(res.total_count);
                }
              })
              .catch((error) => {
                setErrorMessage(error.message);
              });
          }, 1000);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        toggleStartOrComplete(false);
      });
  };

  const handlesterilizeList = () => {
    const type =
      (entityType?.id === "cssd_kit" && "cssdKit") || "assetResponse";

    if (tab === "in_process" || tab === "completed") {
      return (
        <div className={styles["sterilize__table"]}>
          <div
            className={joinClass(
              styles["sterilize__columns-container"],
              tab === "completed" && styles["sterilize__three-columns"]
            )}
          >
            <div>{"Sterilize ID"}</div>
            <div>{"Code"}</div>
            <div>{"Name"}</div>
          </div>
          <div
            className={joinClass(
              styles["sterilize__row-container"],
              tab === "completed" && styles["sterilize__three-row"]
            )}
          >
            {sterilizeList.map((sterilize) => (
              <div key={sterilize.id}>
                <div>{sterilize.cssdKitSterilizeNumber}</div>
                <div>{sterilize[type]?.code}</div>
                <div className={styles["sterilize__split-row"]}>
                  <div>{sterilize[type]?.name}</div>
                  {tab === "in_process" && (
                    <div
                      onClick={() => {
                        toggleStart(true);
                        setRow({
                          sterilizeNumber: sterilize.cssdKitSterilizeNumber,
                          sterilizeId: sterilize.id,
                          ruleExist: sterilize.ruleExist,
                          ...sterilize[type],
                        });
                      }}
                      className={styles["sterilize__icon"]}
                    >
                      <GrCompliance />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles["sterilize"]}>
      <PageHeader themeHeader title={"Sterilize"} returnHome={"/cssd_home"} />
      <NewModal
        isOpen={isStart}
        onCancel={() => {
          toggleStart(false);
          setLineNumber("");
        }}
      >
        <form
          className={styles["sterilize__modal"]}
          onSubmit={(tab === "pending" && handleStart) || handleComplete}
        >
          {!successMessage && (
            <>
              <div className={styles["sterilize__preview"]}>
                {tab !== "pending" && (
                  <div>
                    <div>{"sterilize ID"}</div>
                    <div>{row.sterilizeNumber}</div>
                  </div>
                )}
                <div>
                  <div>{"Code"}</div>
                  <div>{row.code}</div>
                </div>
                <div>
                  <div>{"Name"}</div>
                  <div>{row.name}</div>
                </div>
              </div>
              {(tab === "pending" && (
                <>
                  <AdvancedDropdown
                    options={sterilizationProcessList.map((process) => {
                      return { id: process, label: removeUnderScore(process) };
                    })}
                    value={sterilizationProcess}
                    onChange={(value) => setSterilizationProcess(value)}
                    label="Sterilization Process"
                    required
                  />
                  <TextInput
                    label={"Line Number"}
                    value={lineNumber}
                    onChange={(value) => setLineNumber(value)}
                    required
                  />
                </>
              )) ||
                (!row.ruleExist && (
                  <DatePicker
                    value={expiryDate}
                    onChange={setExpiryDate}
                    label={"Expiry Date"}
                  />
                ))}
              <div className={styles["sterilize__button-container"]}>
                <Button
                  onClick={() => {
                    toggleStart(false);
                    setLineNumber("");
                  }}
                  cancelButton
                  type="button"
                  className={styles["sterilize__button"]}
                >
                  {"Cancel"}
                </Button>
                <Button
                  type="submit"
                  className={styles["sterilize__button"]}
                  disabled={startOrComplete}
                >
                  {(tab === "pending" && "Start") || "Complete"}
                </Button>
              </div>
            </>
          )}
          {successMessage && (
            <label className={styles["sterilize__message"]}>
              {successMessage}
            </label>
          )}
        </form>
      </NewModal>
      <div className={styles["sterilize__main-section"]}>
        <div className={styles["sterilize__filter"]}>
          <AdvancedDropdown
            label={"Area"}
            value={originArea}
            options={handleOriginAreas()}
            onChange={(value) => setOriginArea(value)}
            disabled={handleOriginAreas().length === 1}
          />
          <AdvancedDropdown
            label={"Entity Type"}
            options={[
              { id: "cssd_kit", label: "KITs" },
              { id: "asset", label: "Assets" },
            ]}
            value={entityType}
            onChange={(value) => {
              setEntityType(value);
            }}
          />
        </div>
        <div className={styles["sterilize__tab-container"]}>
          {tabList.map((item) => (
            <div
              id={item.key}
              key={item.key}
              className={joinClass(
                styles["sterilize__tab-container__tab"],
                item.disabled && styles["sterilize__tab-container__disabled"],
                tab === item.key && styles["sterilize__tab-container__active"]
              )}
              onClick={() => {
                if (!item.disabled) {
                  setTab(item.key);
                  setPage(1);
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        {(totalCount > 0 && isLoading === "loaded" && (
          <div className={styles["sterilize__sub-section"]}>
            {tab === "pending" && entityType.id === "cssd_kit" && (
              <div className={styles["sterilize__table"]}>
                <div
                  className={joinClass(
                    styles["sterilize__columns-container"],
                    styles["sterilize__two-columns"]
                  )}
                >
                  <div>{"Code"}</div>
                  <div>{"Name"}</div>
                </div>
                <div
                  className={joinClass(
                    styles["sterilize__row-container"],
                    styles["sterilize__two-row"]
                  )}
                >
                  {cssdKits.map((kit) => (
                    <div key={kit.id}>
                      <div>{kit.code}</div>
                      <div className={styles["sterilize__split-row"]}>
                        <div>{kit.name}</div>
                        <div
                          onClick={() => {
                            toggleStart(true);
                            setRow(kit);
                          }}
                          className={styles["sterilize__icon"]}
                        >
                          <CgBrush />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {tab === "pending" && entityType?.id === "asset" && (
              <div className={styles["sterilize__table"]}>
                <div
                  className={joinClass(
                    styles["sterilize__columns-container"],
                    styles["sterilize__two-columns"]
                  )}
                >
                  <div>{"Code"}</div>
                  <div>{"Name"}</div>
                </div>
                <div
                  className={joinClass(
                    styles["sterilize__row-container"],
                    styles["sterilize__two-row"]
                  )}
                >
                  {instruments.map((instrument) => (
                    <div key={instrument.id}>
                      <div>{instrument.code}</div>
                      <div className={styles["sterilize__split-row"]}>
                        <div>{instrument.name}</div>
                        <div
                          onClick={() => {
                            toggleStart(true);
                            setRow(instrument);
                          }}
                          className={styles["sterilize__icon"]}
                        >
                          <CgBrush />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {handlesterilizeList()}
            {totalCount > 0 && (
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                setPage={setPage}
                listCount={
                  (tab === "pending" &&
                    entityType.id === "cssd_kit" &&
                    cssdKits.length) ||
                  (tab === "pending" &&
                    entityType?.id === "asset" &&
                    instruments.length) ||
                  sterilizeList.length
                }
              />
            )}
          </div>
        )) ||
          (isLoading === "loading" && <Loader />) || (
            <div className={styles["sterilize__error-message"]}>
              {(errorMessage && removeUnderScore(errorMessage)) ||
                "No data available"}
            </div>
          )}
      </div>
    </div>
  );
};

export default Sterilize;
