import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import requestMethods from "../../helpers/request";
import AdvancedDropdown from "../../components/AdvancedDropdown";
import { getItemFromLocalStorage, joinClass } from "../../helpers/utils";
import PageHeader from "../../components/PageHeader";
import PageLayout from "../../components/PageLayout";
import styles from "./index.module.css";

const AssetLocationUpdate = () => {
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [areas, setAreas] = useState([]);
  const [assetData, setAssetData] = useState({});
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [notes, setNotes] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const params = useParams();

  const { company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage("user") || {};

  const unitId = assetData?.unit?.id;

  useEffect(() => {
    if (params.id)
      requestMethods
        .GET({ endpoint: `v3/asset/${companyId}/details/${params?.id}` })
        .then((res) => {
          setAssetData(res);
        });
  }, [params?.id]);

  useEffect(() => {
    if (unitId) {
      requestMethods
        .GET({ endpoint: `v4/building/${companyId}/${unitId}/get-buildings` })
        .then((res) => {
          setBuildings(res.buildings);
        });
    }
  }, [unitId]);

  useEffect(() => {
    if (selectedBuilding?.id) {
      requestMethods
        .GET({
          endpoint: `v4/floor/${companyId}/${unitId}/get-floors`,
          params: {
            building_id: selectedBuilding?.id,
          },
        })
        .then((res) => {
          setFloors(res.floors);
        });
    }
  }, [selectedBuilding?.id]);

  useEffect(() => {
    if (selectedFloor?.id) {
      requestMethods
        .GET({
          endpoint: `v4/area/${companyId}/${unitId}/get-areas`,
          params: {
            building_id: selectedBuilding?.id,
            floor_id: selectedFloor?.id,
          },
        })
        .then((res) => {
          setAreas(res.areas);
        });
    }
  }, [selectedFloor?.id]);

  const dropdownList = [
    {
      id: "1",
      label: "Building",
      options: buildings,
      value: selectedBuilding,
      onChange: (value) => handleChange("building", value, setSelectedBuilding),
      clearable: selectedBuilding?.id && true,
    },
    {
      id: "2",
      label: "Floor",
      options: floors,
      value: selectedFloor,
      onChange: (value) => handleChange("floor", value, setSelectedFloor),
      disabled: !selectedBuilding?.id,
      clearable: true,
    },
    {
      id: "3",
      label: "Area",
      options: areas,
      value: selectedArea,
      onChange: (value) => setSelectedArea(value),
      required: true,
      disabled: !selectedFloor?.id,
      clearable: true,
    },
  ];

  const handleChange = (key, value, setFunction) => {
    if (key === "building") {
      setSelectedFloor(null);
      setSelectedArea(null);
    }
    if (key === "floor") {
      setSelectedArea(null);
    }
    setFunction(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const assetId = params?.id;
    requestMethods
      .PUT({
        endpoint: `/v3/asset/${companyId}/${unitId}/update-home-location/${assetId}`,
        body: {
          area_id: selectedArea?.id,
          notes: notes,
        },
      })
      .then((res) => {
        setSuccessMessage(res.message);
        setTimeout(() => history.push(`/asset/${assetData?.id}`), 2000);
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  }, [errorMessage]);

  return (
    <PageLayout>
      <PageHeader title="Update Location" />
      <form className={styles.location} onSubmit={handleSubmit}>
        <div className={styles["location__header"]}>
          <label className={styles["location__present-location"]}>
            Present Location:
          </label>
          <label>
            {(assetData?.current_home_location &&
              `${assetData?.current_home_location?.building?.name},
                         ${assetData?.current_home_location?.floor?.name}, ${assetData?.current_home_location?.name}`) ||
              "NIL"}
          </label>
        </div>
        <div className={styles["location__header"]}>
          <label className={styles["location__header__change-to"]}>
            Change To :
          </label>
        </div>
        <div className={styles["location__filter-container"]}>
          {dropdownList.map((dropdown) => (
            <AdvancedDropdown
              {...dropdown}
              key={dropdown.id}
              className={styles["location__input"]}
              id={dropdown.id}
            />
          ))}
          <div className={styles["location__input"]}>
            <TextInput
              label="Notes"
              id="areaNote"
              value={notes}
              onChange={(value) => setNotes(value)}
            />
          </div>
        </div>
        <div className={styles["location__button-container"]}>
          <Button
            onClick={() => {
              history.push(`/asset/${assetData?.id}`);
            }}
            cancelButton
            className={styles["location__cancel"]}
          >
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </div>
        {successMessage && (
          <p
            className={joinClass(
              styles.location__message,
              successMessage && styles["location__message--success"]
            )}
          >
            {successMessage}
          </p>
        )}
        {errorMessage && (
          <p
            className={joinClass(
              styles.location__message,
              errorMessage && styles["location__message--error"]
            )}
          >
            {errorMessage}
          </p>
        )}
      </form>
    </PageLayout>
  );
};

export default AssetLocationUpdate;
