import React from "react";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

export const CheckBox = ({ id, head, label, checked, onChange, disabled }) => {
  return (
    <div
      className={joinClass(styles.checkbox, head && styles["checkbox--head"])}
    >
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
