import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router";
import Button from "../../components/Button";
import HomeHeader from "../../components/HomeHeader";
import { AiOutlineScan, AiOutlineHistory, AiOutlineHome } from "react-icons/ai";
import styles from "./index.module.css";
import RadioButton from "../../components/RadioButton";
import { getItemFromLocalStorage } from "../../helpers/utils";
import { permissions } from "../../helpers/permission";
import requestMethods from "../../helpers/request";
import Loader from "../../components/Loader";
import Toaster from "../../components/Toaster";
import { Fragment } from "react";

const Home = () => {
  const history = useHistory();

  const {
    company: { id: companyId = "" } = {},
    units = [],
    privileges = {},
  } = getItemFromLocalStorage("user") || {};

  const [isOpen, toggleReader] = useState(false);
  const [isValidate, toggleValidate] = useState(false);
  const [entity, setEntity] = useState("asset");

  const handleScan = (data) => {
    if (data) {
      const id = data.split("/").pop();

      if (entity === "asset") history.push(`/asset/${id}`);
      else {
        toggleValidate(true);
        requestMethods
          .GET({
            endpoint: `v4/asset/gate-pass/${companyId}/${units[0]?.id}/details/${id}`,
          })
          .then((res) => {
            if (res.status) {
              if (
                res.gate_pass_status !== "returned" &&
                res.gate_pass_status !== "cancelled"
              ) {
                history.push(`/GatePassImageUpload/${id}`);
              } else {
                toggleValidate(false);
                setMessage({
                  text: "Gatepass is Closed or Cancelled",
                  type: "error",
                });
              }
            }
          })
          .catch((error) => {
            setMessage({ text: "Something went wrong", type: "error" });
            console.log(error);
            toggleValidate(false);
          });
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const [message, setMessage] = useState("");

  return (
    <Fragment>
      {message?.text && <Toaster message={message} setMessage={setMessage} />}
      <div className={styles.home}>
        <HomeHeader />
        <div className={styles["home__qr-container"]}>
          {isOpen && (
            <div className={styles["home__qr-reader"]}>
              {!isValidate ? (
                <QrReader
                  delay={100}
                  onError={handleError}
                  onScan={handleScan}
                />
              ) : (
                <Loader />
              )}
            </div>
          )}
          <div className={styles["home__button-container"]}>
            {privileges[permissions.GATE_PASS_VIEW] && (
              <div className={styles["home__radio-buttons"]}>
                <RadioButton
                  label="Asset"
                  name="asset"
                  value={entity}
                  onChange={() => setEntity("asset")}
                />
                <RadioButton
                  label="Gatepass"
                  name="gatepass"
                  value={entity}
                  onChange={() => setEntity("gatepass")}
                />
              </div>
            )}
            <Button
              type="button"
              onClick={() => toggleReader((prevState) => !prevState)}
              className={styles["home__qr-container__button"]}
            >
              <AiOutlineScan />
              {isOpen ? "Stop" : "QR Scan"}
            </Button>
            {entity === "asset" && (
              <Button
                type="button"
                className={styles["home__qr-container__button"]}
                styleReverse
                onClick={() => history.push("/history")}
              >
                <AiOutlineHistory />
                {"Asset History"}
              </Button>
            )}
            <Button
              type="button"
              className={styles["home__qr-container__button"]}
              onClick={() => history.push("/home")}
              styleReverse
            >
              <AiOutlineHome />
              {"Home"}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
