import { useContext } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { AiOutlinePoweroff, AiOutlineScissor } from "react-icons/ai";
import { GiVendingMachine } from "react-icons/gi";
import { appContext } from "../../App";
import {
  filteredDepartmentList,
  getItemFromLocalStorage,
} from "../../helpers/utils";
import { MdGpsFixed } from "react-icons/md";
import styles from "./index.module.css";

const ModuleHome = () => {
  const { userData } = useContext(appContext);
  const history = useHistory();
  const { privileges = [], departments = [] } =
    getItemFromLocalStorage("user") || {};

  const filteredCustodianDepartments = useMemo(() => {
    return filteredDepartmentList(departments, "custodian", true);
  }, [departments]);

  const handleCssdModulePrivileCheck = () => {
    if (filteredCustodianDepartments[0]) {
      const process = filteredCustodianDepartments[0].allowed_process || [];

      const allProcessList = [
        { process: "inspect", privilege: "cssdKitInspectView" },
        { process: "receive", privilege: "cssdKitTransactionView" },
        { process: "pack", privilege: "cssdKitPackingView" },
        { process: "wash", privilege: "cssdKitWashView" },
        { process: "sterilize", privilege: "cssdKitSterilizeView" },
      ];

      let isEnable = false;
      for (let i = 0; i < allProcessList.length; i++) {
        if (
          process.includes(allProcessList[i].process) &&
          privileges[allProcessList[i].privilege]
        ) {
          isEnable = true;
          break;
        }
      }

      return isEnable;
    } else {
      return false;
    }
  };

  const renderHomeIcons = () => {
    const homeIcons = [
      {
        key: 1,
        icon: <GiVendingMachine />,
        label: "EAM",
        access: privileges["assetView"],
        onClick: () => history.push("/eam_home"),
      },
      {
        key: 2,
        icon: <AiOutlineScissor />,
        label: "CSSD",
        access: handleCssdModulePrivileCheck(),
        onClick: () => history.push("/cssd_home"),
      },
      {
        key: 3,
        icon: <MdGpsFixed />,
        label: "SST",
        access:
          privileges["surgeryView"] ||
          privileges["surgicalSpongePacketView"] ||
          privileges["surgicalSpongePacketManage"] ||
          privileges["surgeryManage"],
        onClick: () => history.push("/surgical_sponge_track_home"),
      },
    ];

    return homeIcons.map(
      ({ icon, label, access, onClick, key }) =>
        access && (
          <div
            key={key}
            className={styles["moduleHome__icon-container"]}
            onClick={onClick}
          >
            <div>{icon}</div>
            <label>{label}</label>
          </div>
        )
    );
  };

  const logout = () => {
    localStorage.clear();
    history.replace("/");
  };

  return (
    <div className={styles.moduleHome}>
      <div className={styles["moduleHome__header"]}>
        <div className={styles["moduleHome__header__avatar"]}></div>
        <label>{userData?.name}</label>
        <label>{userData?.role.name}</label>
        <div onClick={logout}>
          <AiOutlinePoweroff />
          <label>{"Logout"}</label>
        </div>
      </div>
      <div className={styles["moduleHome__button-container"]}>
        {renderHomeIcons()}
      </div>
    </div>
  );
};

export default ModuleHome;
