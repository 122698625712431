import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import Button from "../../../../components/Button";
import PageHeader from "../../../../components/PageHeader";
import PageLayout from "../../../../components/PageLayout";
import requestMethods from "../../../../helpers/request";
import {
  filteredDepartmentList,
  getItemFromLocalStorage,
  joinClass,
  redirect,
  removeUnderScore,
} from "../../../../helpers/utils";
import { AiFillEdit } from "react-icons/ai";
import NewModal from "../../../../components/NewModal";
import AdvancedDropdown from "../../../../components/AdvancedDropdown";
import Loader from "../../../../components/Loader";
import styles from "./index.module.css";
import Toaster from "../../../../components/Toaster";
import TextInput from "../../../../components/TextInput";
import { useRef } from "react";

const InspectKit = (history) => {
  const { unitId, kitId } = history.match.params;

  const { company: { id: companyId } = {}, departments = [] } =
    getItemFromLocalStorage("user") || {};

  const filteredCustodianDepartments = useMemo(() => {
    return filteredDepartmentList(departments, "custodian", true);
  }, []);

  const [tab, setTab] = useState("");
  const [loading, setLoading] = useState("none");
  const [errorMessage, setErrorMessage] = useState("");
  const [kitDetails, setKitDetails] = useState({});
  const [availableAndGoodInstruments, setAvailableAndGoodInstruments] =
    useState([]);
  const [
    availableAndNonUsableInstruments,
    setAvailableAndNonUsableInstruments,
  ] = useState([]);
  const [notAvailableInstruments, setNotAvailableInstruments] = useState([]);
  const [isUpdate, toggleUpdate] = useState(false);
  const [row, setRow] = useState({});
  const [available, setAvailable] = useState(null);
  const [condition, SetCondition] = useState(null);
  const [isInspect, toggleInspect] = useState(false);
  const [nextProcess, setNextProcess] = useState({
    id: "",
    process: "",
    status: "",
  });
  const [isNextStep, toggleNextStep] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [key, setKey] = useState("");
  const [isInspected, toggleInspected] = useState(false);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setLoading("loading");
    requestMethods
      .GET({
        endpoint: `/v4/cssd/kit/${companyId}/${unitId}/get/${kitId}`,
      })
      .then((res) => {
        setLoading("loaded");
        setKitDetails(res);
      })
      .catch((error) => setErrorMessage(error.message));
  }, []);

  useEffect(() => {
    if (!tab) {
      if (availableAndGoodInstruments.length > 0) {
        setTab("availableAndGood");
      } else if (availableAndNonUsableInstruments.length > 0) {
        setTab("availableAndNonUsable");
      } else if (notAvailableInstruments.length > 0) {
        setTab("notAvailable");
      }
    }
  }, [
    availableAndGoodInstruments,
    availableAndNonUsableInstruments,
    notAvailableInstruments,
  ]);

  const {
    name: kitName = "",
    code: kitCode = "",
    kit_status: kitStatus = "",
    sterilization_expiry_date: sterilizationExpiry,
  } = kitDetails;

  const details = [
    { key: 1, label: "Code", value: kitCode },
    { key: 2, label: "Name", value: kitName },
    {
      key: 3,
      label: "Sterilization Expiry",
      value:
        (sterilizationExpiry &&
          moment(sterilizationExpiry).format("Do MM YYYY hh:mm A")) ||
        "--",
      isDate: true,
    },
    { key: 4, label: "Status", value: removeUnderScore(kitStatus) },
  ];

  const tabList = [
    {
      key: "availableAndGood",
      label: "Good",
      disabled: availableAndGoodInstruments.length < 1,
    },
    {
      key: "availableAndNonUsable",
      label: "Non Usable",
      disabled: availableAndNonUsableInstruments.length < 1,
    },
  ];

  const handleUpdate = (row, condition) => {
    let state = null;
    let setState = null;

    if (row.currentCondition === "good") {
      state = [...availableAndGoodInstruments];
      setState = setAvailableAndGoodInstruments;
    } else {
      state = [...availableAndNonUsableInstruments];
      setState = setAvailableAndNonUsableInstruments;
    }

    if (state.length > 0) {
      if (state.find((data) => data.id === row.id)) {
        setState([
          ...state.map((data) => {
            if (data.id === row.id) {
              if (row.currentAvailability && row.currentCondition === "good")
                data.updatedCondition = condition;
            }

            return data;
          }),
        ]);
      } else {
        setState([
          ...state,
          {
            ...row,
            ...(row.currentAvailability &&
              row.currentCondition === "good" && {
                [row.updatedCondition]: condition,
              }),
          },
        ]);

        if (inputRef.current) inputRef.current.focus();
      }
    } else {
      setState([
        {
          ...row,
          ...(row.currentAvailability &&
            row.currentCondition === "good" && {
              [row.updatedCondition]: condition,
            }),
        },
      ]);

      if (inputRef.current) inputRef.current.focus();
    }
  };

  const handleInspect = () => {
    toggleInspected(true);
    const availableDevices = {};
    const devicesCondition = {};

    availableAndGoodInstruments.forEach((device) => {
      availableDevices[device.id] = true;
      devicesCondition[device.id] = device.updatedCondition;
    });

    availableAndNonUsableInstruments.forEach((device) => {
      availableDevices[device.id] = true;
      devicesCondition[device.id] = device.currentCondition;
    });

    notAvailableInstruments.forEach((device) => {
      availableDevices[device.id] = false;
      devicesCondition[device.id] = device.condition;
    });

    const body = {
      entity_id: kitId,
      device_occupancy_status: availableDevices,
      device_condition: devicesCondition,
      next_phase: nextProcess.process,
      kit_status: nextProcess.status,
      update_asset_condition: true,
      entity_type: "cssd_kit",
    };

    requestMethods
      .POST({
        endpoint: `v4/cssd/kit/inspect/${companyId}/${unitId}/create`,
        body,
      })
      .then((res) => {
        setSuccessMessage("Inspected Successfully");
        setTimeout(() => {
          toggleInspected(false);
          redirect("/cssd_home/inspect");
        }, 1000);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const checkBoxList = [
    {
      key: 1,
      process: "wash",
      label: "Wash",
      status: "awaiting_wash",
      id: "wash",
    },
    {
      key: 2,
      process: "pack",
      label: "Pack",
      status: "awaiting_pack",
      id: "pack",
    },
    {
      key: 3,
      process: "sterilize",
      label: "Sterilize",
      status: "awaiting_sterilize",
      id: "sterilize",
    },
    {
      key: 4,
      process: "dispatch",
      label: "Dispatch",
      status: "awaiting_dispatch",
      id: "dispatch",
    },
  ];

  useEffect(() => {
    handleDefaultNextProcess();
  }, []);

  const handleDefaultNextProcess = () => {
    let nextProcess = null;
    for (let i = 0; i < checkBoxList.length; i++) {
      if (
        filteredCustodianDepartments[0]?.allowed_process?.includes(
          checkBoxList[i].process
        )
      ) {
        nextProcess = checkBoxList[i];
        break;
      }
    }
    setNextProcess(nextProcess);
  };

  const handleClearFn = () => {
    toggleInspect(false);
    toggleNextStep(false);
    handleDefaultNextProcess();
  };

  const handleScan = (e) => {
    e.preventDefault();

    const newCode = Number(code);

    if (newCode > 0) {
      const allDevices = [
        ...kitDetails.available_devices,
        ...kitDetails.not_available_devices,
      ];

      const device = allDevices.find((device) => device.one_dcode === newCode);

      if (device) {
        if (device.condition === "active") {
          if (availableAndGoodInstruments.find((ele) => ele.id === device.id)) {
            setMessage({ text: "Instrument already exist", type: "error" });
          } else {
            const newDevice = {
              code: device.code,
              name: device.name,
              id: device.id,
              currentCondition:
                (device.condition === "active" && "good") ||
                (device.condition === "damaged" && "damaged") ||
                "non_usable",
              updatedCondition:
                (device.condition === "active" && "good") ||
                (device.condition === "damaged" && "damaged") ||
                "non_usable",
            };

            toggleUpdate(true);
            setRow(newDevice);
            SetCondition(newDevice.updatedCondition);
            setKey("availableAndGood");
          }
        } else {
          if (
            availableAndNonUsableInstruments.find((ele) => ele.id === device.id)
          ) {
            setMessage({ text: "Instrument already exist", type: "error" });
          } else {
            const newDevice = {
              code: device.code,
              name: device.name,
              id: device.id,
              currentCondition: device.condition,
            };

            toggleUpdate(true);
            setRow(newDevice);
            SetCondition(newDevice.currentCondition);
            setKey("availableAndNonUsable");
          }
        }
      } else setMessage({ text: "Unknown Instrument", type: "error" });

      setCode("");
    }
  };

  const inputRef = useRef();

  return (
    <PageLayout>
      {message.text && <Toaster message={message} setMessage={setMessage} />}
      <div className={styles["inspect-kit"]}>
        <PageHeader themeHeader title={kitName} />
        <NewModal isOpen={isUpdate} onCancel={() => toggleUpdate(false)}>
          <>
            <div className={styles["inspect-kit__preview"]}>
              <div>
                <div>{"Code"}</div>
                <div>{row.code}</div>
              </div>
              <div>
                <div>{"Name"}</div>
                <div>{row.name}</div>
              </div>
            </div>
            <div className={styles["inspect-kit__input-container"]}>
              {(row.currentCondition === "good" && (
                <AdvancedDropdown
                  label="Condition"
                  value={
                    (condition === "good" && {
                      id: "good",
                      label: "Good",
                    }) ||
                    (condition === "damaged" && {
                      id: "damaged",
                      label: "Damaged",
                    }) || { id: "non_usable", label: "Non Usable" }
                  }
                  options={[
                    { id: "good", label: "Good" },
                    { id: "damaged", label: "Damaged" },
                    { id: "non_usable", label: "Non Usable" },
                  ]}
                  onChange={(value) => {
                    SetCondition(value.id);
                  }}
                />
              )) || (
                <div className={styles["inspect-kit__warning"]}>
                  <label>{"Condition"}</label>
                  <label>
                    {row.currentCondition === "damaged"
                      ? "Damaged"
                      : "Non Usable"}
                  </label>
                </div>
              )}
            </div>
            <div className={styles["inspect-kit__button-container"]}>
              <Button
                onClick={() => toggleUpdate(false)}
                cancelButton
                className={styles["inspect-kit__button"]}
              >
                {"Cancel"}
              </Button>
              <Button
                onClick={() => {
                  handleUpdate(row, condition);
                  toggleUpdate(false);
                }}
                className={styles["inspect-kit__button"]}
                disabled={isInspected}
              >
                {"Update"}
              </Button>
            </div>
          </>
        </NewModal>
        <NewModal isOpen={isInspect} onCancel={handleClearFn}>
          {!successMessage && (
            <div className={styles["radio-container"]}>
              <div className={styles["radio-container__process"]}>
                <label>{"Next Process :"}</label>
                <label>{nextProcess.label}</label>
              </div>
              <div className={styles["radio-container__check"]}>
                <u onClick={() => toggleNextStep(!isNextStep)}>{"Change ?"}</u>
              </div>
              {isNextStep && (
                <AdvancedDropdown
                  options={checkBoxList.filter((item) =>
                    filteredCustodianDepartments[0]?.allowed_process?.includes(
                      item.process
                    )
                  )}
                  value={nextProcess}
                  onChange={(value) => setNextProcess(value)}
                />
              )}
            </div>
          )}
          {!successMessage && (
            <div className={styles["radio-container__button-container"]}>
              <Button
                onClick={handleClearFn}
                cancelButton
                className={styles["radio-container__button"]}
              >
                {"Cancel"}
              </Button>
              <Button
                onClick={handleInspect}
                className={styles["radio-container__button"]}
                disabled={isInspected}
              >
                {"Confirm"}
              </Button>
            </div>
          )}
          {successMessage && (
            <label className={styles["radio-container__message"]}>
              {successMessage}
            </label>
          )}
        </NewModal>
        {(loading === "loaded" && (
          <div className={styles["inspect-kit__main-section"]}>
            <div className={styles["inspect-kit__details-container"]}>
              {details.map((detail) => (
                <div
                  className={styles["inspect-kit__details-container__data"]}
                  key={detail.key}
                >
                  <div>{detail.label}</div>
                  <div
                    {...(detail.isDate && {
                      className: styles["inspect-kit__details-container__date"],
                    })}
                  >
                    {detail.value}
                  </div>
                  {detail.subValue && (
                    <div
                      className={
                        styles["inspect-kit__details-container__sub-value"]
                      }
                    >
                      {detail.subValue}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <form onSubmit={handleScan} className={styles["inspect-kit__text"]}>
              <TextInput
                value={code}
                onChange={(text) => setCode(text)}
                autoFocus
                inputRef={inputRef}
                placeholder={"Scan Barcode"}
              />
            </form>
            <div className={styles["inspect-kit__tab-container"]}>
              {tabList.map((item) => (
                <div
                  id={item.key}
                  key={item.key}
                  className={joinClass(
                    styles["inspect-kit__tab-container__tab"],
                    item.disabled &&
                      styles["inspect-kit__tab-container__disabled"],
                    tab === item.key &&
                      styles["inspect-kit__tab-container__active"]
                  )}
                  onClick={() => !item.disabled && setTab(item.key)}
                >
                  {item.label}
                </div>
              ))}
            </div>
            {availableAndGoodInstruments.length > 0 &&
              tab === "availableAndGood" && (
                <div className={styles["inspect-kit__table"]}>
                  <div className={styles["inspect-kit__columns-container"]}>
                    <div>{"Code"}</div>
                    <div>{"Name"}</div>
                  </div>
                  <div className={styles["inspect-kit__row-container"]}>
                    {availableAndGoodInstruments.map((device) => (
                      <div key={device.id}>
                        <div>{device.code}</div>
                        <div className={styles["inspect-kit__split-row"]}>
                          <div>{device.name}</div>
                          <div
                            onClick={() => {
                              toggleUpdate(true);
                              setRow(device);
                              setAvailable(device.updatedAvailability);
                              SetCondition(device.updatedCondition);
                              setKey("availableAndGood");
                            }}
                          >
                            <AiFillEdit />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {availableAndNonUsableInstruments.length > 0 &&
              tab === "availableAndNonUsable" && (
                <div className={styles["inspect-kit__table"]}>
                  <div className={styles["inspect-kit__columns-container"]}>
                    <div>{"Code"}</div>
                    <div>{"Name"}</div>
                  </div>
                  <div className={styles["inspect-kit__row-container"]}>
                    {availableAndNonUsableInstruments.map((device) => (
                      <div key={device.id}>
                        <div>{device.code}</div>
                        <div className={styles["inspect-kit__split-row"]}>
                          <div>{device.name}</div>
                          <div
                            onClick={() => {
                              toggleUpdate(true);
                              setRow(device);
                              setAvailable(device.updatedAvailability);
                              setKey("availableAndNonUsable");
                            }}
                          >
                            <AiFillEdit />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {notAvailableInstruments.length > 0 && tab === "notAvailable" && (
              <div className={styles["inspect-kit__table"]}>
                <div className={styles["inspect-kit__columns-container"]}>
                  <div>{"Code"}</div>
                  <div>{"Name"}</div>
                </div>
                <div className={styles["inspect-kit__row-container"]}>
                  {notAvailableInstruments.map((device) => (
                    <div key={device.id}>
                      <div>{device.code}</div>
                      <div>{device.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Button
              className={styles["inspect-kit__update"]}
              disabled={isInspected}
              onClick={() => {
                toggleInspect(true);
              }}
            >
              {"Update"}
            </Button>
          </div>
        )) ||
          (loading === "loading" && <Loader />) || (
            <div className={styles["inspect-kit__message"]}>
              {(errorMessage && removeUnderScore(errorMessage)) ||
                "Something went wrong"}
            </div>
          )}
      </div>
    </PageLayout>
  );
};

export default InspectKit;
